import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { PurchaseOrders_purchaseorders_library_homeComponent } from './PurchaseOrders.purchaseorders_library_home.component';
import { PurchaseOrders_inbound_orders_summary_reportComponent } from './PurchaseOrders.inbound_orders_summary_report.component';
import { PurchaseOrders_inbound_unloading_tally_reportComponent } from './PurchaseOrders.inbound_unloading_tally_report.component';
import { PurchaseOrders_open_putaway_reportComponent } from './PurchaseOrders.open_putaway_report.component';
import { PurchaseOrders_purchase_order_reportComponent } from './PurchaseOrders.purchase_order_report.component';
import { PurchaseOrders_receiving_reportComponent } from './PurchaseOrders.receiving_report.component';
import { PurchaseOrders_orderclasses_dd_singleComponent } from './PurchaseOrders.orderclasses_dd_single.component';
import { PurchaseOrders_orderstatuses_dd_singleComponent } from './PurchaseOrders.orderstatuses_dd_single.component';
import { PurchaseOrders_owners_dd_singleComponent } from './PurchaseOrders.owners_dd_single.component';
import { PurchaseOrders_projects_dd_singleComponent } from './PurchaseOrders.projects_dd_single.component';
import { PurchaseOrders_purchase_order_date_types_dd_singleComponent } from './PurchaseOrders.purchase_order_date_types_dd_single.component';
import { PurchaseOrders_reasoncodes_dd_singleComponent } from './PurchaseOrders.reasoncodes_dd_single.component';
import { PurchaseOrders_warehouses_dd_singleComponent } from './PurchaseOrders.warehouses_dd_single.component';
import { PurchaseOrders_orderclasses_dd_multiComponent } from './PurchaseOrders.orderclasses_dd_multi.component';
import { PurchaseOrders_orderstatuses_dd_multiComponent } from './PurchaseOrders.orderstatuses_dd_multi.component';
import { PurchaseOrders_owners_dd_multiComponent } from './PurchaseOrders.owners_dd_multi.component';
import { PurchaseOrders_projects_dd_multiComponent } from './PurchaseOrders.projects_dd_multi.component';
import { PurchaseOrders_purchase_order_date_types_dd_multiComponent } from './PurchaseOrders.purchase_order_date_types_dd_multi.component';
import { PurchaseOrders_reasoncodes_dd_multiComponent } from './PurchaseOrders.reasoncodes_dd_multi.component';
import { PurchaseOrders_warehouses_dd_multiComponent } from './PurchaseOrders.warehouses_dd_multi.component';
import { PurchaseOrders_order_total_received_gross_by_date_widgetComponent } from './PurchaseOrders.order_total_received_gross_by_date_widget.component';
import { PurchaseOrders_order_total_received_order_count_by_date_widgetComponent } from './PurchaseOrders.order_total_received_order_count_by_date_widget.component';
import { PurchaseOrders_order_total_received_units_by_date_widgetComponent } from './PurchaseOrders.order_total_received_units_by_date_widget.component';
import { PurchaseOrders_orderline_total_amount_widgetComponent } from './PurchaseOrders.orderline_total_amount_widget.component';
import { PurchaseOrders_orderline_total_gross_received_widgetComponent } from './PurchaseOrders.orderline_total_gross_received_widget.component';
import { PurchaseOrders_orderline_total_received_widgetComponent } from './PurchaseOrders.orderline_total_received_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public PurchaseOrders: PurchaseOrders_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openpurchaseorders_library_home(replaceCurrentView?: boolean) {
    this.logger.log('PurchaseOrders', 'purchaseorders_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'PurchaseOrders_purchaseorders_library_home',
        component: PurchaseOrders_purchaseorders_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openpurchaseorders_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('PurchaseOrders', 'purchaseorders_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PurchaseOrders_purchaseorders_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openinbound_orders_summary_report(inParams:{ startDate: string, endDate: string }, replaceCurrentView?: boolean) {
    this.logger.log('PurchaseOrders', 'inbound_orders_summary_report');
    ShellService.openViewRequest$.next(
      {
        title: 'Inbound Orders Summary',
        referenceName: 'PurchaseOrders_inbound_orders_summary_report',
        component: PurchaseOrders_inbound_orders_summary_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinbound_orders_summary_reportDialog(
    inParams:{ startDate: string, endDate: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('PurchaseOrders', 'inbound_orders_summary_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_inbound_orders_summary_reportComponent,
      'Inbound Orders Summary',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinbound_unloading_tally_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('PurchaseOrders', 'inbound_unloading_tally_report');
    ShellService.openViewRequest$.next(
      {
        title: 'Unloading Tally Report',
        referenceName: 'PurchaseOrders_inbound_unloading_tally_report',
        component: PurchaseOrders_inbound_unloading_tally_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinbound_unloading_tally_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('PurchaseOrders', 'inbound_unloading_tally_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_inbound_unloading_tally_reportComponent,
      'Unloading Tally Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openopen_putaway_report(inParams:{ startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Open Putaway Report',
        referenceName: 'PurchaseOrders_open_putaway_report',
        component: PurchaseOrders_open_putaway_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openopen_putaway_reportDialog(
    inParams:{ startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_open_putaway_reportComponent,
      'Open Putaway Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('PurchaseOrders', 'purchase_order_report');
    ShellService.openViewRequest$.next(
      {
        title: 'Purchase order report',
        referenceName: 'PurchaseOrders_purchase_order_report',
        component: PurchaseOrders_purchase_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('PurchaseOrders', 'purchase_order_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_purchase_order_reportComponent,
      'Purchase order report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreceiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'PurchaseOrders_receiving_report',
        component: PurchaseOrders_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreceiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PurchaseOrders_purchaseorders_library_home') {
      this.logger.log('PurchaseOrders', 'purchaseorders_library_home');
      const title = 'Home';
      const component = PurchaseOrders_purchaseorders_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_inbound_orders_summary_report') {
      this.logger.log('PurchaseOrders', 'inbound_orders_summary_report');
      const title = 'Inbound Orders Summary';
      const component = PurchaseOrders_inbound_orders_summary_reportComponent;
      const inParams:{ startDate: string, endDate: string } = { startDate: null, endDate: null };
      if (!isNil(params.get('startDate'))) {
        const paramValueString = params.get('startDate');
        // TODO: date
        inParams.startDate = paramValueString;
              }
      if (!isNil(params.get('endDate'))) {
        const paramValueString = params.get('endDate');
        // TODO: date
        inParams.endDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_inbound_unloading_tally_report') {
      this.logger.log('PurchaseOrders', 'inbound_unloading_tally_report');
      const title = 'Unloading Tally Report';
      const component = PurchaseOrders_inbound_unloading_tally_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_open_putaway_report') {
      const title = 'Open Putaway Report';
      const component = PurchaseOrders_open_putaway_reportComponent;
      const inParams:{ startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] } = { startDate: null, endDate: null, ownerId: null, projectId: null, warehouseId: [] };
      if (!isNil(params.get('startDate'))) {
        const paramValueString = params.get('startDate');
        // TODO: date
        inParams.startDate = paramValueString;
              }
      if (!isNil(params.get('endDate'))) {
        const paramValueString = params.get('endDate');
        // TODO: date
        inParams.endDate = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_purchase_order_report') {
      this.logger.log('PurchaseOrders', 'purchase_order_report');
      const title = 'Purchase order report';
      const component = PurchaseOrders_purchase_order_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_receiving_report') {
      const title = 'Receiving Report';
      const component = PurchaseOrders_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderclasses_dd_single') {
      const title = 'Orderclasses by purchase order Dropdown.';
      const component = PurchaseOrders_orderclasses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderstatuses_dd_single') {
      const title = 'Order Statuses';
      const component = PurchaseOrders_orderstatuses_dd_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_owners_dd_single') {
      this.logger.log('PurchaseOrders', 'owners_dd_single');
      const title = 'Owners dropdown';
      const component = PurchaseOrders_owners_dd_singleComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_projects_dd_single') {
      this.logger.log('PurchaseOrders', 'projects_dd_single');
      const title = 'Projects dropdown';
      const component = PurchaseOrders_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_purchase_order_date_types_dd_single') {
      const title = 'purchase_order_date_types_dd';
      const component = PurchaseOrders_purchase_order_date_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_reasoncodes_dd_single') {
      this.logger.log('PurchaseOrders', 'reasoncodes_dd_single');
      const title = 'Reasoncodes dropdown';
      const component = PurchaseOrders_reasoncodes_dd_singleComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_warehouses_dd_single') {
      this.logger.log('PurchaseOrders', 'warehouses_dd_single');
      const title = 'Warehouses Dropdown';
      const component = PurchaseOrders_warehouses_dd_singleComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderclasses_dd_multi') {
      const title = 'Orderclasses by purchase order Dropdown.';
      const component = PurchaseOrders_orderclasses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderstatuses_dd_multi') {
      const title = 'Order Statuses';
      const component = PurchaseOrders_orderstatuses_dd_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_owners_dd_multi') {
      this.logger.log('PurchaseOrders', 'owners_dd_multi');
      const title = 'Owners dropdown';
      const component = PurchaseOrders_owners_dd_multiComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_projects_dd_multi') {
      this.logger.log('PurchaseOrders', 'projects_dd_multi');
      const title = 'Projects dropdown';
      const component = PurchaseOrders_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_purchase_order_date_types_dd_multi') {
      const title = 'purchase_order_date_types_dd';
      const component = PurchaseOrders_purchase_order_date_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_reasoncodes_dd_multi') {
      this.logger.log('PurchaseOrders', 'reasoncodes_dd_multi');
      const title = 'Reasoncodes dropdown';
      const component = PurchaseOrders_reasoncodes_dd_multiComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_warehouses_dd_multi') {
      this.logger.log('PurchaseOrders', 'warehouses_dd_multi');
      const title = 'Warehouses Dropdown';
      const component = PurchaseOrders_warehouses_dd_multiComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_order_total_received_gross_by_date_widget') {
      const title = 'Recv gross today';
      const component = PurchaseOrders_order_total_received_gross_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [], orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_order_total_received_order_count_by_date_widget') {
      const title = 'Recv orders today';
      const component = PurchaseOrders_order_total_received_order_count_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [], orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_order_total_received_units_by_date_widget') {
      const title = 'Recv units today';
      const component = PurchaseOrders_order_total_received_units_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [], orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderline_total_amount_widget') {
      const title = 'Total amount';
      const component = PurchaseOrders_orderline_total_amount_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderline_total_gross_received_widget') {
      const title = 'Total gross recv';
      const component = PurchaseOrders_orderline_total_gross_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_orderline_total_received_widget') {
      const title = 'Total received';
      const component = PurchaseOrders_orderline_total_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
