import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService, EModalSize, EToasterType, EToasterPosition } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'LocusRobotics-open_orders',
  templateUrl: './LocusRobotics.open_orders.component.html'
})
export class LocusRobotics_open_ordersComponent extends BaseComponent implements OnInit {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs


  //#region Variables
  //#endregion  
  private entity: { Id?: number, Account?: { LookupCode?: string, Name?: string, AccountsContactsLookup?: { AccountId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
private logger: CleanupLoggerService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  136930 
    };

    const data = await this.datasources.LocusRobotics.ds_get_order_account_address_by_orderId.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = this.format(27, 1);

    
    this.originalValue = 27;
  }

  format(num: number, digits: number): string {
    // at some point in time we should probably respect the selected culture from the localization service
    return isNil(num)
      ? ''
      : Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: digits}).format(num);
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
