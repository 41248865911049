import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 



interface IFootPrintManager_pallet_transactions_shipment_gridComponentEntity {
PalletClassId?: number, In?: number, Out?: number, Date?: string, Employee?: string, PalletClassName?: string, Reference?: string, Notes?: string, Amount?: number, Id?: number}

interface IFootPrintManager_pallet_transactions_shipment_gridComponentInParams {
  project_id?: number, shipment_id?: number, order_lookup_code?: string}


class FootPrintManager_pallet_transactions_shipment_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_pallet_transactions_shipment_gridComponent;
  entity: IFootPrintManager_pallet_transactions_shipment_gridComponentEntity;


  options: { edit_transaction: ButtonModel } = {
    edit_transaction: new ButtonModel('edit_transaction', new ButtonStyles(null, null), false, 'Edit transaction', '')

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    PalletClass: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Direction: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    In: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Out: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Employee: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_pallet_transactions_shipment_gridComponent, entity: IFootPrintManager_pallet_transactions_shipment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.PalletClassId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_pallet_transactions_shipment_gridComponent, entity?: IFootPrintManager_pallet_transactions_shipment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.PalletClassId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.PalletClassId;
    const inParams = {
      $keys:[$resultKey],
      shipment_id:  $grid.inParams.shipment_id ,
      aggregate:  $grid.filters.aggregate.control.value ,
      full_text_search:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.PalletTransactions.ds_pallet_transactions_shipment_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.PalletClass.displayControl as TextModel).text = $row.entity.PalletClassName;
    (this.cells.Amount.displayControl as TextModel).text = $row.entity.Amount?.toLocaleString();
    (this.cells.In.displayControl as TextModel).text = $row.entity.In?.toLocaleString();
    (this.cells.Out.displayControl as TextModel).text = $row.entity.Out?.toLocaleString();
    (this.cells.Reference.displayControl as TextModel).text = $row.entity.Reference;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.Employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.Date.displayControl as TextModel).text = $row.entity.Date?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $row: FootPrintManager_pallet_transactions_shipment_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_shipment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_save');
  //O.Arias - 05/14/2024
  
  /*
  
  let ProjectId: number = $grid.inParams.project_id;
  let ShipmentId: number = $grid.inParams.shipment_id;
  
  let Id: number = $row.entity.Id;
  let PalletClassId: number = $row.cells.PalletClass.editControl.value;
  let Reference: string = $row.cells.Reference.editControl.value;
  let Notes: string = $row.cells.Notes.editControl.value;
  let Amount: number = $row.cells.Amount.editControl.value;
  
  let aggregate: boolean = $grid.filters.aggregate.control.value ?? false;
  
  let inbound_old: number = $row.entity.In ?? 0;
  let outbound_old: number = $row.entity.Out ?? 0;
  
  let inbound: number = $row.cells.In.editControl.value ?? 0;
  let outbound: number = $row.cells.Out.editControl.value ?? 0;
  
  if (inbound < 0) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The "In" value cannot be less than zero! To deduct pallets use the "Out" value!`);
      return;
  }
  
  if (outbound < 0) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The "Out" value cannot be less than zero! To add pallets use the "In" value!`);
      return;
  }
  
  if (!$utils.isDefined(PalletClassId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `You need to select a pallet class!`);
      return;
  }
  
  let inbound_delta: number = inbound - inbound_old;
  let outbound_delta: number = outbound - outbound_old;
  let pallet_class_update: boolean = false;
  
  if (!$utils.isDefined($row.entity.PalletClassId)) {
      //This is a new row
      $row.entity.PalletClassId = PalletClassId;
      $row.entity.Reference = Reference;
      $row.entity.Notes = Notes;
  } else {
      //Check if there was a pallet class change
      if (PalletClassId !== $row.entity.PalletClassId) {
          pallet_class_update = true;
      }
  }
  
  let payload: {
      remove?: boolean,
      id?: number,
      project_id: number,
      shipment_id?: number,
      carrier_id?: number,
      account_id?: number,
      pallet_class_id: number,
      reference?: string,
      notes?: string,
      amount?: number
  }
  
  payload = {
      project_id: ProjectId,
      pallet_class_id: PalletClassId
  }
  
  if ($utils.isDefined(ShipmentId)) { payload.shipment_id = ShipmentId }
  if ($utils.isDefined(Reference)) { payload.reference = Reference.trim() }
  if ($utils.isDefined(Notes)) { payload.notes = Notes.trim() }
  
  try {
  
      if (aggregate) {
  
          if (pallet_class_update) {
              //Since we're updating the pallet class at the aggregate level we need to make four transactions to support the change
  
              //Undo the old transactions
              if (inbound_old > 0) {
                  payload.pallet_class_id = $row.entity.PalletClassId
                  payload.amount = -1 * inbound_old;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
              if (outbound_old > 0) {
                  payload.pallet_class_id = $row.entity.PalletClassId
                  payload.amount = outbound_old;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
  
              //Apply the new transactions
              if (inbound > 0) {
                  payload.pallet_class_id = PalletClassId
                  payload.amount = inbound;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
              if (outbound > 0) {
                  payload.pallet_class_id = PalletClassId
                  payload.amount = -1 * outbound;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
          } else {
  
              //if inbound delta is a positive number it means we're adding pallets to inventory, else we're deducting
              if (inbound_delta != 0) {
                  payload.amount = inbound_delta;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
  
              //if outbound delta is a positive number it means we're deducting pallets from inventory, else we're adding
              if (outbound_delta != 0) {
                  payload.amount = -1 * outbound_delta; //It's the opposite of the inbound, hence the -1.
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
          }
      } else {
  
          if ($utils.isDefined(Id)) { payload.id = Id }
          payload.amount = Amount ?? 0;
          let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
          if (!create_or_update.success) {
              await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
          }
      }
  
      $grid.refresh();
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; };
      if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } };
      await $shell.PalletTransactions.openErrorDialog(`Failed!`, targetError.message);
  }
  
  */
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_pallet_transactions_shipment_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_shipment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_row_data_loaded');
  //O.Arias - 05/13/2024
  
  // Set Date/Time Formats
  const format = `${$settings.PalletTransactions.DateFormat}, ${$settings.PalletTransactions.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined($row.entity.Date)) {
      $row.cells.Date.displayControl.text = $utils.date.format($row.entity.Date, format);
  }
  
  let inAmount: number = $row.entity.Amount ?? 0;
  
  $row.cells.Direction.displayControl.text = (inAmount > 0 ? "In" : (inAmount < 0 ? "Out" : ""));
  $row.cells.Amount.displayControl.text = inAmount?.toString();
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_pallet_transactions_shipment_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_shipment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_init_new_row');
  $row.entity = {In: null, Out: null}
  }
  on_edit_transaction(event = null) {
    return this.on_edit_transactionInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_transactionInternal(
    $row: FootPrintManager_pallet_transactions_shipment_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_shipment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_edit_transaction');
  //O.Arias - 09/18/2024
  
  let aggregate: boolean = $grid.filters.aggregate.control.value ?? false;
  
  if (aggregate) {
  
      await $shell.FootPrintManager.openpallet_transaction_creation_formDialog({
          project_id: $grid.inParams.project_id,
          shipment_id: $grid.inParams.shipment_id,
          order_lookup_code: $grid.inParams.order_lookup_code,
          pallet_class_id: $row.entity.PalletClassId,
          transaction_id: $row.entity.Id,
          pallets_in: $row.entity.In,
          pallets_out: $row.entity.Out
      }, "flyout")
  
  } else {
  
      await $shell.FootPrintManager.openpallet_transaction_creation_formDialog({
          project_id: $grid.inParams.project_id,
          shipment_id: $grid.inParams.shipment_id,
          order_lookup_code: $grid.inParams.order_lookup_code,
          pallet_class_id: $row.entity.PalletClassId,
          transaction_id: $row.entity.Id,
          amount: $row.entity.Amount
      }, "flyout")
  }
  await $row.refresh()
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-pallet_transactions_shipment_grid',
  templateUrl: './FootPrintManager.pallet_transactions_shipment_grid.component.html'
})
export class FootPrintManager_pallet_transactions_shipment_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_pallet_transactions_shipment_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_pallet_transactions_shipment_gridComponentInParams = { project_id: null, shipment_id: null, order_lookup_code: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     PalletClass: new GridHeaderModel(new HeaderStyles(null, null), 'Pallet class', false, false, null),       Amount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount', false, false, null),       Direction: new GridHeaderModel(new HeaderStyles(null, null), 'Direction', false, false, null),       In: new GridHeaderModel(new HeaderStyles(null, null), 'In', false, false, null),       Out: new GridHeaderModel(new HeaderStyles(null, null), 'Out', false, false, null),       Reference: new GridHeaderModel(new HeaderStyles(null, null), 'Reference', false, false, null),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       Employee: new GridHeaderModel(new HeaderStyles(null, null), 'Employee', false, false, null),       Date: new GridHeaderModel(new HeaderStyles(null, null), 'Date', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_pallet_transactions_shipment_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('shipment_id') set $inParams_shipment_id(value: any) {
    this.inParams['shipment_id'] = value;
  }
  get $inParams_shipment_id(): any {
    return this.inParams['shipment_id'] ;
  }
  @Input('order_lookup_code') set $inParams_order_lookup_code(value: any) {
    this.inParams['order_lookup_code'] = value;
  }
  get $inParams_order_lookup_code(): any {
    return this.inParams['order_lookup_code'] ;
  }

  topToolbar = {
      pallet_transaction: new ToolModel(new ButtonModel('pallet_transaction', new ButtonStyles(null, null), false, 'New pallet transaction', 'icon-ic_fluent_add_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    aggregate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    aggregate: new FieldModel(new CheckBoxModel(this.formGroup.controls['aggregate'] as DatexFormControl, null, false, 'Aggregate')
, new ControlContainerStyles(null, null), '', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Pallet transactions shipment grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.aggregate.control as CheckBoxModel).reset(true);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['aggregate']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_aggregate_change();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shipment_id:  $grid.inParams.shipment_id ,
      aggregate:  $grid.filters.aggregate.control.value ,
      full_text_search:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.PalletTransactions.ds_pallet_transactions_shipment_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_pallet_transactions_shipment_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_pallet_transactions_shipment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_init');
  //O.Arias - 06/25/2024
  
  $grid.headers.Amount.hidden = true;
  $grid.headers.Direction.hidden = true;
  
  if ($utils.isDefined($grid.inParams.order_lookup_code)) {
      $grid.title = `Pallet transactions for order ${$grid.inParams.order_lookup_code}`
  } else {
      $grid.title = `Pallet transactions for shipment id ${$grid.inParams.shipment_id}`
  }
  }
  on_aggregate_change(event = null) {
    return this.on_aggregate_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_aggregate_changeInternal(
    $grid: FootPrintManager_pallet_transactions_shipment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_aggregate_change');
  //O.Arias - 05/13/2024
  
  let aggregate = $grid.filters.aggregate.control.value ?? false;
  
  if (aggregate) {
      $grid.headers.Amount.hidden = true;
      $grid.headers.Direction.hidden = true;
      $grid.headers.In.hidden = false;
      $grid.headers.Out.hidden = false;
  } else {
      $grid.headers.Amount.hidden = false;
      $grid.headers.Direction.hidden = false;
      $grid.headers.In.hidden = true;
      $grid.headers.Out.hidden = true;
  }
  }
  on_create_pallet_transaction(event = null) {
    return this.on_create_pallet_transactionInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_pallet_transactionInternal(
    $grid: FootPrintManager_pallet_transactions_shipment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'pallet_transactions_shipment_grid.on_create_pallet_transaction');
  //O.Arias - 06/25/2024
  
  await $shell.FootPrintManager.openpallet_transaction_creation_formDialog({
      project_id: $grid.inParams.project_id,
      shipment_id: $grid.inParams.shipment_id,
      order_lookup_code: $grid.inParams.order_lookup_code
  }, "flyout")
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
