import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 


import { Invoices_invoicing_transactions_gridComponent } from './Invoices.invoicing_transactions_grid.component';
import { Invoices_invoicing_logs_gridComponent } from './Invoices.invoicing_logs_grid.component';
import { Invoices_invoicing_rule_template_exceptions_gridComponent } from './Invoices.invoicing_rule_template_exceptions_grid.component';
import { Invoices_priorities_gridComponent } from './Invoices.priorities_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_invoicing_transactions_gridComponent),
    forwardRef(() => Invoices_invoicing_logs_gridComponent),
    forwardRef(() => Invoices_invoicing_rule_template_exceptions_gridComponent),
    forwardRef(() => Invoices_priorities_gridComponent),
  ],
  selector: 'Invoices-invoicing_debug_hub',
  templateUrl: './Invoices.invoicing_debug_hub.component.html'
})
export class Invoices_invoicing_debug_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
  };

  actionbar = {
      auto_email_execute_main: new ToolModel(new ButtonModel('auto_email_execute_main', new ButtonStyles(null, null), false, 'Execute auto-invoicing rules', 'icon-ic_fluent_play_circle_20_regular')
    )
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      rule_data: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      transactions: new TabItemModel(
        this.rootTabGroup, 
        'Transactions', 
        ),
      logs: new TabItemModel(
        this.rootTabGroup, 
        'Logs', 
        ),
      rule_data: new TabItemModel(
        this.rootTabGroup, 
        'Rule data', 
        true,
        {
          exceptions: new TabItemModel(
          this.subTabGroups.rule_data, 
          'Exceptions', 
          ),
          priorities: new TabItemModel(
          this.subTabGroups.rule_data, 
          'Priorities', 
          ),
        }
        ),
    };
  
    //#region tabs inParams
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_transactions', { read: Invoices_invoicing_transactions_gridComponent }) $tabs_transactions: Invoices_invoicing_transactions_gridComponent;
      @ViewChild('$tabs_logs', { read: Invoices_invoicing_logs_gridComponent }) $tabs_logs: Invoices_invoicing_logs_gridComponent;
        @ViewChild('$tabs_rule_data_exceptions', { read: Invoices_invoicing_rule_template_exceptions_gridComponent }) $tabs_rule_data_exceptions: Invoices_invoicing_rule_template_exceptions_gridComponent;
        @ViewChild('$tabs_rule_data_priorities', { read: Invoices_priorities_gridComponent }) $tabs_rule_data_priorities: Invoices_priorities_gridComponent;
    //#endregion tabs children




  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Invoices_ShellService,
  private datasources: Invoices_DatasourceService,
  private flows: Invoices_FlowService,
  private reports: Invoices_ReportService,
  private localization: Invoices_LocalizationService,
  private operations: Invoices_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.transactions,
      this.tabs.logs,
      this.tabs.rule_data,
    ]; 
    this.subTabGroups.rule_data.tabs = [
        this.tabs.rule_data.tabs.exceptions,
        this.tabs.rule_data.tabs.priorities,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Debug';
    
    const $hub = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_transactions') {
      if (!isNil(this.$tabs_transactions) && !this.tabs.transactions.hidden) {
        this.$tabs_transactions.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_logs') {
      if (!isNil(this.$tabs_logs) && !this.tabs.logs.hidden) {
        this.$tabs_logs.refresh(true, false, null);
      }
    }
      if (childToSkip !== '$tabs_rule_data_exceptions') {
        if (!isNil(this.$tabs_rule_data_exceptions) && !this.tabs.rule_data.tabs.exceptions.hidden) {
          this.$tabs_rule_data_exceptions.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_rule_data_priorities') {
        if (!isNil(this.$tabs_rule_data_priorities) && !this.tabs.rule_data.tabs.priorities.hidden) {
          this.$tabs_rule_data_priorities.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_auto_email_execute_main_clicked(event = null) {
    return this.on_auto_email_execute_main_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_email_execute_main_clickedInternal(
    $hub: Invoices_invoicing_debug_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'invoicing_debug_hub.on_auto_email_execute_main_clicked');
  const result = await $flows.Invoices.invoicing_main({});
  
  if ($utils.isDefined(result.messages)) {
      await $shell.Invoices.openInfoDialog('Auto-email execute main result', result.messages.join(' | '));
  } else {
      $shell.Invoices.openToaster('Auto-email execute main succeeded', null, EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  }
  
  $hub.refresh();
  }
  //#endregion private flows
}
