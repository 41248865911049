import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_tester_form',
  templateUrl: './app.custom_tester_form.component.html'
})
export class app_custom_tester_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    shipment_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dock_appointment_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inventory_count_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    flow_input_1: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    flow_input_2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    flow_input_3: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    shipment_id: new FieldModel(new NumberBoxModel(this.formGroup.controls['shipment_id'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Shipment ID', false)
,
    cartonize_shipment: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, 'Cartonize', '')
, new ControlContainerStyles(null, null), '', false)
,
    palletize_shipment: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, 'Palletize', '')
, new ControlContainerStyles(null, null), '', false)
,
    dock_appointment_id: new FieldModel(new TextBoxModel(this.formGroup.controls['dock_appointment_id'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Appointment ID', false)
,
    open_custom_editor: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, 'Open custom editor', '')
, new ControlContainerStyles(null, null), '', false)
,
    inventory_count_id: new FieldModel(new TextBoxModel(this.formGroup.controls['inventory_count_id'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Inventory count ID', false)
,
    open_custom_inventory_count_editor: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Open custom editor', '')
, new ControlContainerStyles(['creation'], null), '', false)
,
    flow_input_1: new FieldModel(new TextBoxModel(this.formGroup.controls['flow_input_1'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Input 1', false)
,
    flow_input_2: new FieldModel(new TextBoxModel(this.formGroup.controls['flow_input_2'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Input 2', false)
,
    flow_input_3: new FieldModel(new TextBoxModel(this.formGroup.controls['flow_input_3'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Input 3', false)
,
    execute_flow: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, 'Execute flow', '')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  cartonization: new FieldsetModel('Cartonization', false, true, false),
  dock_appointments: new FieldsetModel('Dock appointments', false, true, false),
  inventory_counts: new FieldsetModel('Inventory counts', false, true, false),
  flows: new FieldsetModel('Flows', false, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    shipment_id: this.fields.shipment_id.control.valueChanges
    ,
    dock_appointment_id: this.fields.dock_appointment_id.control.valueChanges
    ,
    inventory_count_id: this.fields.inventory_count_id.control.valueChanges
    ,
    flow_input_1: this.fields.flow_input_1.control.valueChanges
    ,
    flow_input_2: this.fields.flow_input_2.control.valueChanges
    ,
    flow_input_3: this.fields.flow_input_3.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'custom_tester_form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .shipment_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dock_appointment_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .inventory_count_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .flow_input_1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .flow_input_2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .flow_input_3
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cartonize_shipment_clicked(event = null) {
    return this.on_cartonize_shipment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cartonize_shipment_clickedInternal(
    $form: app_custom_tester_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_tester_form.on_cartonize_shipment_clicked');
  if ($utils.isDefined($form.fields.shipment_id.control.value)) {
      let result = (await $flows.Cartonization.cartonize_shipment_flow({ shipmentId: $form.fields.shipment_id.control.value }));
  
      if ($utils.isDefined(result.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue);
      }
  }
  else {
      await $shell.openErrorDialog('Error cartonizing shipment', 'Please provide valid value for shipment ID.');
  }
  }
  on_palletize_shipment_clicked(event = null) {
    return this.on_palletize_shipment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_palletize_shipment_clickedInternal(
    $form: app_custom_tester_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_tester_form.on_palletize_shipment_clicked');
  if ($utils.isDefined($form.fields.shipment_id.control.value)) {
      let result = (await $flows.Cartonization.palletize_shipment_flow({ shipmentId: $form.fields.shipment_id.control.value }));
  
      if ($utils.isDefined(result.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue);
      }
  }
  else {
      await $shell.openErrorDialog('Error palletizing shipment', 'Please provide valid value for shipment ID.');
  }
  }
  on_open_custom_editor_clicked(event = null) {
    return this.on_open_custom_editor_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_open_custom_editor_clickedInternal(
    $form: app_custom_tester_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_tester_form.on_open_custom_editor_clicked');
  
  if ($utils.isDefined($form.fields.dock_appointment_id.control.value) && !isNaN($form.fields.dock_appointment_id.control.value)) {
      await $shell.opencustom_dock_appointment_editorDialog({ dockAppointmentId: Number($form.fields.dock_appointment_id.control.value) });
  }
  else {
      await $shell.openErrorDialog('Error opening dock appointment editor', `Invalid value provided for dock appointment ID: '${$form.fields.dock_appointment_id.control.value}'`)
  }
  }
  on_open_custom_inventory_count_editor_clicked(event = null) {
    return this.on_open_custom_inventory_count_editor_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_open_custom_inventory_count_editor_clickedInternal(
    $form: app_custom_tester_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_tester_form.on_open_custom_inventory_count_editor_clicked');
  
  if ($utils.isDefined($form.fields.inventory_count_id.control.value) && !isNaN($form.fields.inventory_count_id.control.value)) {
      await $shell.opencustom_inventory_count_editor({ inventoryCountId: Number($form.fields.inventory_count_id.control.value) });
  }
  else {
      await $shell.openErrorDialog('Error opening inventory count editor', `Invalid value provided for inventory count ID: '${$form.fields.dock_appointment_id.control.value}'`)
  }
  }
  on_execute_flow_clicked(event = null) {
    return this.on_execute_flow_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_execute_flow_clickedInternal(
    $form: app_custom_tester_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_tester_form.on_execute_flow_clicked');
  let result = (await $flows.custom_get_blind_return_new_licenseplate_status({
      expirationDate: $form.fields.flow_input_1.control.value,
      lotStatusId: Number($form.fields.flow_input_2.control.value),
      otherProperties: JSON.parse($form.fields.flow_input_3.control.value)
  }));
  
  if ($utils.isDefined(result.reasons)) {
      await $shell.openErrorDialog('Location is invalid', result.reasons.join(', '));
  }
  else {
      await $shell.openInfoDialog('Location is valid', null);
  }
  }
  //#endregion private flows
}
