import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';

import { LocusRobotics_ds_bot_configuration_gridService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_current_jobs_widgetService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_find_material_packagingsService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_count_task_infoService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_inventory_count_by_lookupcodeService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_inventory_count_infoService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_job_prioritizationService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_jobs_by_orderIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_licenseplate_by_licenseplateIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_licenseplate_by_lookupcodeService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_location_lookup_by_idService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_material_by_lookupService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_materials_by_jobIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_materials_by_materialIdsService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_open_jobs_widgetService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_open_replenishments_by_orderService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_orderId_by_jobIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_order_account_address_by_orderIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_order_address_by_orderId_top1Service } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_order_ship_to_account_vs_order_addressService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_orders_ready_to_queueService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_printersService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_project_by_orderIdsService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_project_by_projectIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_serialNumber_validationService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_serial_number_by_IdsService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_serial_number_by_lookupService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_shipping_containers_ready_to_queueService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_shipping_containers_tasksService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_task_info_by_taskIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_tasks_ready_for_queueService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_get_warehouse_by_warehouseIdService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_jobs_by_botService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_jobs_detail_gridService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_jobs_header_by_orderId_gridService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_jobs_header_gridService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_measurement_units_ddService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_owners_ddService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_projects_ddService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_scanned_values_gridService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_total_jobs_widgetService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_tote_gridService } from './LocusRobotics.datasource.index';
import { LocusRobotics_ds_warehouses_ddService } from './LocusRobotics.datasource.index';
import { LocusRobotics_get_location_by_lookupcode_and_warehouseService } from './LocusRobotics.datasource.index';
import { LocusRobotics_get_lot_by_lookup_materialService } from './LocusRobotics.datasource.index';
import { LocusRobotics_get_pick_tasks_by_order_material_locationService } from './LocusRobotics.datasource.index';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.InventoryCounts = this.injector.get(InventoryCounts_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public InventoryCounts: InventoryCounts_DatasourceService;
  public LocusRobotics: LocusRobotics_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_bot_configuration_grid: LocusRobotics_ds_bot_configuration_gridService;
  public get ds_bot_configuration_grid(): LocusRobotics_ds_bot_configuration_gridService {
    if(!this._ds_bot_configuration_grid) {
      this._ds_bot_configuration_grid = this.injector.get(LocusRobotics_ds_bot_configuration_gridService);
    }
    return this._ds_bot_configuration_grid;
  }
  private _ds_current_jobs_widget: LocusRobotics_ds_current_jobs_widgetService;
  public get ds_current_jobs_widget(): LocusRobotics_ds_current_jobs_widgetService {
    if(!this._ds_current_jobs_widget) {
      this._ds_current_jobs_widget = this.injector.get(LocusRobotics_ds_current_jobs_widgetService);
    }
    return this._ds_current_jobs_widget;
  }
  private _ds_find_material_packagings: LocusRobotics_ds_find_material_packagingsService;
  public get ds_find_material_packagings(): LocusRobotics_ds_find_material_packagingsService {
    if(!this._ds_find_material_packagings) {
      this._ds_find_material_packagings = this.injector.get(LocusRobotics_ds_find_material_packagingsService);
    }
    return this._ds_find_material_packagings;
  }
  private _ds_get_count_task_info: LocusRobotics_ds_get_count_task_infoService;
  public get ds_get_count_task_info(): LocusRobotics_ds_get_count_task_infoService {
    if(!this._ds_get_count_task_info) {
      this._ds_get_count_task_info = this.injector.get(LocusRobotics_ds_get_count_task_infoService);
    }
    return this._ds_get_count_task_info;
  }
  private _ds_get_inventory_count_by_lookupcode: LocusRobotics_ds_get_inventory_count_by_lookupcodeService;
  public get ds_get_inventory_count_by_lookupcode(): LocusRobotics_ds_get_inventory_count_by_lookupcodeService {
    if(!this._ds_get_inventory_count_by_lookupcode) {
      this._ds_get_inventory_count_by_lookupcode = this.injector.get(LocusRobotics_ds_get_inventory_count_by_lookupcodeService);
    }
    return this._ds_get_inventory_count_by_lookupcode;
  }
  private _ds_get_inventory_count_info: LocusRobotics_ds_get_inventory_count_infoService;
  public get ds_get_inventory_count_info(): LocusRobotics_ds_get_inventory_count_infoService {
    if(!this._ds_get_inventory_count_info) {
      this._ds_get_inventory_count_info = this.injector.get(LocusRobotics_ds_get_inventory_count_infoService);
    }
    return this._ds_get_inventory_count_info;
  }
  private _ds_get_job_prioritization: LocusRobotics_ds_get_job_prioritizationService;
  public get ds_get_job_prioritization(): LocusRobotics_ds_get_job_prioritizationService {
    if(!this._ds_get_job_prioritization) {
      this._ds_get_job_prioritization = this.injector.get(LocusRobotics_ds_get_job_prioritizationService);
    }
    return this._ds_get_job_prioritization;
  }
  private _ds_get_jobs_by_orderId: LocusRobotics_ds_get_jobs_by_orderIdService;
  public get ds_get_jobs_by_orderId(): LocusRobotics_ds_get_jobs_by_orderIdService {
    if(!this._ds_get_jobs_by_orderId) {
      this._ds_get_jobs_by_orderId = this.injector.get(LocusRobotics_ds_get_jobs_by_orderIdService);
    }
    return this._ds_get_jobs_by_orderId;
  }
  private _ds_get_licenseplate_by_licenseplateId: LocusRobotics_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): LocusRobotics_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(LocusRobotics_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplate_by_lookupcode: LocusRobotics_ds_get_licenseplate_by_lookupcodeService;
  public get ds_get_licenseplate_by_lookupcode(): LocusRobotics_ds_get_licenseplate_by_lookupcodeService {
    if(!this._ds_get_licenseplate_by_lookupcode) {
      this._ds_get_licenseplate_by_lookupcode = this.injector.get(LocusRobotics_ds_get_licenseplate_by_lookupcodeService);
    }
    return this._ds_get_licenseplate_by_lookupcode;
  }
  private _ds_get_location_lookup_by_id: LocusRobotics_ds_get_location_lookup_by_idService;
  public get ds_get_location_lookup_by_id(): LocusRobotics_ds_get_location_lookup_by_idService {
    if(!this._ds_get_location_lookup_by_id) {
      this._ds_get_location_lookup_by_id = this.injector.get(LocusRobotics_ds_get_location_lookup_by_idService);
    }
    return this._ds_get_location_lookup_by_id;
  }
  private _ds_get_material_by_lookup: LocusRobotics_ds_get_material_by_lookupService;
  public get ds_get_material_by_lookup(): LocusRobotics_ds_get_material_by_lookupService {
    if(!this._ds_get_material_by_lookup) {
      this._ds_get_material_by_lookup = this.injector.get(LocusRobotics_ds_get_material_by_lookupService);
    }
    return this._ds_get_material_by_lookup;
  }
  private _ds_get_materials_by_jobId: LocusRobotics_ds_get_materials_by_jobIdService;
  public get ds_get_materials_by_jobId(): LocusRobotics_ds_get_materials_by_jobIdService {
    if(!this._ds_get_materials_by_jobId) {
      this._ds_get_materials_by_jobId = this.injector.get(LocusRobotics_ds_get_materials_by_jobIdService);
    }
    return this._ds_get_materials_by_jobId;
  }
  private _ds_get_materials_by_materialIds: LocusRobotics_ds_get_materials_by_materialIdsService;
  public get ds_get_materials_by_materialIds(): LocusRobotics_ds_get_materials_by_materialIdsService {
    if(!this._ds_get_materials_by_materialIds) {
      this._ds_get_materials_by_materialIds = this.injector.get(LocusRobotics_ds_get_materials_by_materialIdsService);
    }
    return this._ds_get_materials_by_materialIds;
  }
  private _ds_get_open_jobs_widget: LocusRobotics_ds_get_open_jobs_widgetService;
  public get ds_get_open_jobs_widget(): LocusRobotics_ds_get_open_jobs_widgetService {
    if(!this._ds_get_open_jobs_widget) {
      this._ds_get_open_jobs_widget = this.injector.get(LocusRobotics_ds_get_open_jobs_widgetService);
    }
    return this._ds_get_open_jobs_widget;
  }
  private _ds_get_open_replenishments_by_order: LocusRobotics_ds_get_open_replenishments_by_orderService;
  public get ds_get_open_replenishments_by_order(): LocusRobotics_ds_get_open_replenishments_by_orderService {
    if(!this._ds_get_open_replenishments_by_order) {
      this._ds_get_open_replenishments_by_order = this.injector.get(LocusRobotics_ds_get_open_replenishments_by_orderService);
    }
    return this._ds_get_open_replenishments_by_order;
  }
  private _ds_get_orderId_by_jobId: LocusRobotics_ds_get_orderId_by_jobIdService;
  public get ds_get_orderId_by_jobId(): LocusRobotics_ds_get_orderId_by_jobIdService {
    if(!this._ds_get_orderId_by_jobId) {
      this._ds_get_orderId_by_jobId = this.injector.get(LocusRobotics_ds_get_orderId_by_jobIdService);
    }
    return this._ds_get_orderId_by_jobId;
  }
  private _ds_get_order_account_address_by_orderId: LocusRobotics_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): LocusRobotics_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(LocusRobotics_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: LocusRobotics_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): LocusRobotics_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(LocusRobotics_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_ship_to_account_vs_order_address: LocusRobotics_ds_get_order_ship_to_account_vs_order_addressService;
  public get ds_get_order_ship_to_account_vs_order_address(): LocusRobotics_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._ds_get_order_ship_to_account_vs_order_address) {
      this._ds_get_order_ship_to_account_vs_order_address = this.injector.get(LocusRobotics_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address;
  }
  private _ds_get_orders_ready_to_queue: LocusRobotics_ds_get_orders_ready_to_queueService;
  public get ds_get_orders_ready_to_queue(): LocusRobotics_ds_get_orders_ready_to_queueService {
    if(!this._ds_get_orders_ready_to_queue) {
      this._ds_get_orders_ready_to_queue = this.injector.get(LocusRobotics_ds_get_orders_ready_to_queueService);
    }
    return this._ds_get_orders_ready_to_queue;
  }
  private _ds_get_printers: LocusRobotics_ds_get_printersService;
  public get ds_get_printers(): LocusRobotics_ds_get_printersService {
    if(!this._ds_get_printers) {
      this._ds_get_printers = this.injector.get(LocusRobotics_ds_get_printersService);
    }
    return this._ds_get_printers;
  }
  private _ds_get_project_by_orderIds: LocusRobotics_ds_get_project_by_orderIdsService;
  public get ds_get_project_by_orderIds(): LocusRobotics_ds_get_project_by_orderIdsService {
    if(!this._ds_get_project_by_orderIds) {
      this._ds_get_project_by_orderIds = this.injector.get(LocusRobotics_ds_get_project_by_orderIdsService);
    }
    return this._ds_get_project_by_orderIds;
  }
  private _ds_get_project_by_projectId: LocusRobotics_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): LocusRobotics_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(LocusRobotics_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_serialNumber_validation: LocusRobotics_ds_get_serialNumber_validationService;
  public get ds_get_serialNumber_validation(): LocusRobotics_ds_get_serialNumber_validationService {
    if(!this._ds_get_serialNumber_validation) {
      this._ds_get_serialNumber_validation = this.injector.get(LocusRobotics_ds_get_serialNumber_validationService);
    }
    return this._ds_get_serialNumber_validation;
  }
  private _ds_get_serial_number_by_Ids: LocusRobotics_ds_get_serial_number_by_IdsService;
  public get ds_get_serial_number_by_Ids(): LocusRobotics_ds_get_serial_number_by_IdsService {
    if(!this._ds_get_serial_number_by_Ids) {
      this._ds_get_serial_number_by_Ids = this.injector.get(LocusRobotics_ds_get_serial_number_by_IdsService);
    }
    return this._ds_get_serial_number_by_Ids;
  }
  private _ds_get_serial_number_by_lookup: LocusRobotics_ds_get_serial_number_by_lookupService;
  public get ds_get_serial_number_by_lookup(): LocusRobotics_ds_get_serial_number_by_lookupService {
    if(!this._ds_get_serial_number_by_lookup) {
      this._ds_get_serial_number_by_lookup = this.injector.get(LocusRobotics_ds_get_serial_number_by_lookupService);
    }
    return this._ds_get_serial_number_by_lookup;
  }
  private _ds_get_shipping_containers_ready_to_queue: LocusRobotics_ds_get_shipping_containers_ready_to_queueService;
  public get ds_get_shipping_containers_ready_to_queue(): LocusRobotics_ds_get_shipping_containers_ready_to_queueService {
    if(!this._ds_get_shipping_containers_ready_to_queue) {
      this._ds_get_shipping_containers_ready_to_queue = this.injector.get(LocusRobotics_ds_get_shipping_containers_ready_to_queueService);
    }
    return this._ds_get_shipping_containers_ready_to_queue;
  }
  private _ds_get_shipping_containers_tasks: LocusRobotics_ds_get_shipping_containers_tasksService;
  public get ds_get_shipping_containers_tasks(): LocusRobotics_ds_get_shipping_containers_tasksService {
    if(!this._ds_get_shipping_containers_tasks) {
      this._ds_get_shipping_containers_tasks = this.injector.get(LocusRobotics_ds_get_shipping_containers_tasksService);
    }
    return this._ds_get_shipping_containers_tasks;
  }
  private _ds_get_task_info_by_taskId: LocusRobotics_ds_get_task_info_by_taskIdService;
  public get ds_get_task_info_by_taskId(): LocusRobotics_ds_get_task_info_by_taskIdService {
    if(!this._ds_get_task_info_by_taskId) {
      this._ds_get_task_info_by_taskId = this.injector.get(LocusRobotics_ds_get_task_info_by_taskIdService);
    }
    return this._ds_get_task_info_by_taskId;
  }
  private _ds_get_tasks_ready_for_queue: LocusRobotics_ds_get_tasks_ready_for_queueService;
  public get ds_get_tasks_ready_for_queue(): LocusRobotics_ds_get_tasks_ready_for_queueService {
    if(!this._ds_get_tasks_ready_for_queue) {
      this._ds_get_tasks_ready_for_queue = this.injector.get(LocusRobotics_ds_get_tasks_ready_for_queueService);
    }
    return this._ds_get_tasks_ready_for_queue;
  }
  private _ds_get_warehouse_by_warehouseId: LocusRobotics_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): LocusRobotics_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(LocusRobotics_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_jobs_by_bot: LocusRobotics_ds_jobs_by_botService;
  public get ds_jobs_by_bot(): LocusRobotics_ds_jobs_by_botService {
    if(!this._ds_jobs_by_bot) {
      this._ds_jobs_by_bot = this.injector.get(LocusRobotics_ds_jobs_by_botService);
    }
    return this._ds_jobs_by_bot;
  }
  private _ds_jobs_detail_grid: LocusRobotics_ds_jobs_detail_gridService;
  public get ds_jobs_detail_grid(): LocusRobotics_ds_jobs_detail_gridService {
    if(!this._ds_jobs_detail_grid) {
      this._ds_jobs_detail_grid = this.injector.get(LocusRobotics_ds_jobs_detail_gridService);
    }
    return this._ds_jobs_detail_grid;
  }
  private _ds_jobs_header_by_orderId_grid: LocusRobotics_ds_jobs_header_by_orderId_gridService;
  public get ds_jobs_header_by_orderId_grid(): LocusRobotics_ds_jobs_header_by_orderId_gridService {
    if(!this._ds_jobs_header_by_orderId_grid) {
      this._ds_jobs_header_by_orderId_grid = this.injector.get(LocusRobotics_ds_jobs_header_by_orderId_gridService);
    }
    return this._ds_jobs_header_by_orderId_grid;
  }
  private _ds_jobs_header_grid: LocusRobotics_ds_jobs_header_gridService;
  public get ds_jobs_header_grid(): LocusRobotics_ds_jobs_header_gridService {
    if(!this._ds_jobs_header_grid) {
      this._ds_jobs_header_grid = this.injector.get(LocusRobotics_ds_jobs_header_gridService);
    }
    return this._ds_jobs_header_grid;
  }
  private _ds_measurement_units_dd: LocusRobotics_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): LocusRobotics_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(LocusRobotics_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_owners_dd: LocusRobotics_ds_owners_ddService;
  public get ds_owners_dd(): LocusRobotics_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(LocusRobotics_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: LocusRobotics_ds_projects_ddService;
  public get ds_projects_dd(): LocusRobotics_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(LocusRobotics_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_scanned_values_grid: LocusRobotics_ds_scanned_values_gridService;
  public get ds_scanned_values_grid(): LocusRobotics_ds_scanned_values_gridService {
    if(!this._ds_scanned_values_grid) {
      this._ds_scanned_values_grid = this.injector.get(LocusRobotics_ds_scanned_values_gridService);
    }
    return this._ds_scanned_values_grid;
  }
  private _ds_total_jobs_widget: LocusRobotics_ds_total_jobs_widgetService;
  public get ds_total_jobs_widget(): LocusRobotics_ds_total_jobs_widgetService {
    if(!this._ds_total_jobs_widget) {
      this._ds_total_jobs_widget = this.injector.get(LocusRobotics_ds_total_jobs_widgetService);
    }
    return this._ds_total_jobs_widget;
  }
  private _ds_tote_grid: LocusRobotics_ds_tote_gridService;
  public get ds_tote_grid(): LocusRobotics_ds_tote_gridService {
    if(!this._ds_tote_grid) {
      this._ds_tote_grid = this.injector.get(LocusRobotics_ds_tote_gridService);
    }
    return this._ds_tote_grid;
  }
  private _ds_warehouses_dd: LocusRobotics_ds_warehouses_ddService;
  public get ds_warehouses_dd(): LocusRobotics_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(LocusRobotics_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
  private _get_location_by_lookupcode_and_warehouse: LocusRobotics_get_location_by_lookupcode_and_warehouseService;
  public get get_location_by_lookupcode_and_warehouse(): LocusRobotics_get_location_by_lookupcode_and_warehouseService {
    if(!this._get_location_by_lookupcode_and_warehouse) {
      this._get_location_by_lookupcode_and_warehouse = this.injector.get(LocusRobotics_get_location_by_lookupcode_and_warehouseService);
    }
    return this._get_location_by_lookupcode_and_warehouse;
  }
  private _get_lot_by_lookup_material: LocusRobotics_get_lot_by_lookup_materialService;
  public get get_lot_by_lookup_material(): LocusRobotics_get_lot_by_lookup_materialService {
    if(!this._get_lot_by_lookup_material) {
      this._get_lot_by_lookup_material = this.injector.get(LocusRobotics_get_lot_by_lookup_materialService);
    }
    return this._get_lot_by_lookup_material;
  }
  private _get_pick_tasks_by_order_material_location: LocusRobotics_get_pick_tasks_by_order_material_locationService;
  public get get_pick_tasks_by_order_material_location(): LocusRobotics_get_pick_tasks_by_order_material_locationService {
    if(!this._get_pick_tasks_by_order_material_location) {
      this._get_pick_tasks_by_order_material_location = this.injector.get(LocusRobotics_get_pick_tasks_by_order_material_locationService);
    }
    return this._get_pick_tasks_by_order_material_location;
  }
}

