import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { AsnOrders_asnorders_library_homeComponent } from './AsnOrders.asnorders_library_home.component';
import { AsnOrders_set_asn_receive_task_testComponent } from './AsnOrders.set_asn_receive_task_test.component';
import { AsnOrders_license_plate_label_reportComponent } from './AsnOrders.license_plate_label_report.component';
import { AsnOrders_license_plate_labels_by_order_reportComponent } from './AsnOrders.license_plate_labels_by_order_report.component';
import { AsnOrders_receiving_reportComponent } from './AsnOrders.receiving_report.component';
import { AsnOrders_licenseplates_by_order_dd_singleComponent } from './AsnOrders.licenseplates_by_order_dd_single.component';
import { AsnOrders_orderclasses_dd_singleComponent } from './AsnOrders.orderclasses_dd_single.component';
import { AsnOrders_licenseplates_by_order_dd_multiComponent } from './AsnOrders.licenseplates_by_order_dd_multi.component';
import { AsnOrders_orderclasses_dd_multiComponent } from './AsnOrders.orderclasses_dd_multi.component';
import { AsnOrders_orderline_total_amount_widgetComponent } from './AsnOrders.orderline_total_amount_widget.component';
import { AsnOrders_orderline_total_gross_received_widgetComponent } from './AsnOrders.orderline_total_gross_received_widget.component';
import { AsnOrders_orderline_total_received_widgetComponent } from './AsnOrders.orderline_total_received_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public AsnOrders: AsnOrders_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openasnorders_library_home(replaceCurrentView?: boolean) {
    this.logger.log('AsnOrders', 'asnorders_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'AsnOrders_asnorders_library_home',
        component: AsnOrders_asnorders_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openasnorders_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('AsnOrders', 'asnorders_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      AsnOrders_asnorders_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openset_asn_receive_task_test(inParams:{ existingTaskId?: number, licenseplateId?: number, lotId?: number, materialId?: number, orderId?: number, packagedAmount?: number, packagingId?: number, vendorlotId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('AsnOrders', 'set_asn_receive_task_test');
    ShellService.openViewRequest$.next(
      {
        title: 'set_asn_receive_task_test',
        referenceName: 'AsnOrders_set_asn_receive_task_test',
        component: AsnOrders_set_asn_receive_task_testComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openset_asn_receive_task_testDialog(
    inParams:{ existingTaskId?: number, licenseplateId?: number, lotId?: number, materialId?: number, orderId?: number, packagedAmount?: number, packagingId?: number, vendorlotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ taskId?: number, orderLineNumber?: number }> {
    this.logger.log('AsnOrders', 'set_asn_receive_task_test');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      AsnOrders_set_asn_receive_task_testComponent,
      'set_asn_receive_task_test',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicense_plate_label_report(inParams:{ licensePlateId: number }, replaceCurrentView?: boolean) {
    this.logger.log('AsnOrders', 'license_plate_label_report');
    ShellService.openViewRequest$.next(
      {
        title: 'license_plate_label_report',
        referenceName: 'AsnOrders_license_plate_label_report',
        component: AsnOrders_license_plate_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicense_plate_label_reportDialog(
    inParams:{ licensePlateId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('AsnOrders', 'license_plate_label_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      AsnOrders_license_plate_label_reportComponent,
      'license_plate_label_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicense_plate_labels_by_order_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('AsnOrders', 'license_plate_labels_by_order_report');
    ShellService.openViewRequest$.next(
      {
        title: 'license_plate_labels_by_order_report',
        referenceName: 'AsnOrders_license_plate_labels_by_order_report',
        component: AsnOrders_license_plate_labels_by_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicense_plate_labels_by_order_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('AsnOrders', 'license_plate_labels_by_order_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      AsnOrders_license_plate_labels_by_order_reportComponent,
      'license_plate_labels_by_order_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreceiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'AsnOrders_receiving_report',
        component: AsnOrders_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreceiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      AsnOrders_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'AsnOrders_asnorders_library_home') {
      this.logger.log('AsnOrders', 'asnorders_library_home');
      const title = 'Home';
      const component = AsnOrders_asnorders_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_set_asn_receive_task_test') {
      this.logger.log('AsnOrders', 'set_asn_receive_task_test');
      const title = 'set_asn_receive_task_test';
      const component = AsnOrders_set_asn_receive_task_testComponent;
      const inParams:{ existingTaskId?: number, licenseplateId?: number, lotId?: number, materialId?: number, orderId?: number, packagedAmount?: number, packagingId?: number, vendorlotId?: number } = { existingTaskId: null, licenseplateId: null, lotId: null, materialId: null, orderId: null, packagedAmount: null, packagingId: null, vendorlotId: null };
      if (!isNil(params.get('existingTaskId'))) {
        const paramValueString = params.get('existingTaskId');
        inParams.existingTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorlotId'))) {
        const paramValueString = params.get('vendorlotId');
        inParams.vendorlotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_license_plate_label_report') {
      this.logger.log('AsnOrders', 'license_plate_label_report');
      const title = 'license_plate_label_report';
      const component = AsnOrders_license_plate_label_reportComponent;
      const inParams:{ licensePlateId: number } = { licensePlateId: null };
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_license_plate_labels_by_order_report') {
      this.logger.log('AsnOrders', 'license_plate_labels_by_order_report');
      const title = 'license_plate_labels_by_order_report';
      const component = AsnOrders_license_plate_labels_by_order_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_receiving_report') {
      const title = 'Receiving Report';
      const component = AsnOrders_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_licenseplates_by_order_dd_single') {
      const title = 'licenseplates_by_order_dd';
      const component = AsnOrders_licenseplates_by_order_dd_singleComponent;
      const inParams:{ orderId: number, shipmentId: number, warehouseId: number, defaultLocationId: number } = { orderId: null, shipmentId: null, warehouseId: null, defaultLocationId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('defaultLocationId'))) {
        const paramValueString = params.get('defaultLocationId');
        inParams.defaultLocationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_orderclasses_dd_single') {
      const title = 'orderclasses_dd';
      const component = AsnOrders_orderclasses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_licenseplates_by_order_dd_multi') {
      const title = 'licenseplates_by_order_dd';
      const component = AsnOrders_licenseplates_by_order_dd_multiComponent;
      const inParams:{ orderId: number, shipmentId: number, warehouseId: number, defaultLocationId: number } = { orderId: null, shipmentId: null, warehouseId: null, defaultLocationId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('defaultLocationId'))) {
        const paramValueString = params.get('defaultLocationId');
        inParams.defaultLocationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_orderclasses_dd_multi') {
      const title = 'orderclasses_dd';
      const component = AsnOrders_orderclasses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_orderline_total_amount_widget') {
      const title = 'Total amount';
      const component = AsnOrders_orderline_total_amount_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_orderline_total_gross_received_widget') {
      const title = 'Total gross recv';
      const component = AsnOrders_orderline_total_gross_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'AsnOrders_orderline_total_received_widget') {
      const title = 'Total received';
      const component = AsnOrders_orderline_total_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
