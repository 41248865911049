import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ds_get_task_info_by_taskIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId?: number }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number, ExpectedPackagedPackId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocationId?: number, ExpectedTargetLicensePlateId?: number, ExpectedTargetShippingContainerId?: number, MaterialId?: number, ProjectId?: number, ShipmentId?: number, WarehouseId?: number, ExpectedSourceLocation?: { Id?: number, LicensePlates?: { Id?: number }[] }, ExpectedTargetShippingContainer?: { LookupCode?: string } } }> 
  {
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_get_task_info_by_taskId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
