import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { Cartonization_DatasourceService } from './Cartonization.datasource.index';
import { LaborManagement_DatasourceService } from './LaborManagement.datasource.index';
import { LoadContainers_DatasourceService } from './LoadContainers.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { PackVerification_DatasourceService } from './PackVerification.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { Replenishments_DatasourceService } from './Replenishments.datasource.index';
import { ShippingContainers_DatasourceService } from './ShippingContainers.datasource.index';
import { TemperatureReadings_DatasourceService } from './TemperatureReadings.datasource.index';
import { Waves_DatasourceService } from './Waves.datasource.index';
import { WorkOrders_DatasourceService } from './WorkOrders.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { ExcelLocationImport_DatasourceService } from './ExcelLocationImport.datasource.index';
import { SerialNumbers_DatasourceService } from './SerialNumbers.datasource.index';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { WarehouseTransfers_DatasourceService } from './WarehouseTransfers.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';

import { app_custom_asn_order_get_lotService } from './app.datasource.index';
import { app_custom_asn_order_get_lotsService } from './app.datasource.index';
import { app_custom_asn_order_get_materialService } from './app.datasource.index';
import { app_custom_asn_order_get_material_packagingService } from './app.datasource.index';
import { app_custom_asn_order_get_materialsService } from './app.datasource.index';
import { app_custom_asn_order_get_orderValidationResultsService } from './app.datasource.index';
import { app_custom_asn_order_get_shipmentOrderLookupService } from './app.datasource.index';
import { app_custom_asn_order_get_target_materialService } from './app.datasource.index';
import { app_custom_asn_order_get_tasksService } from './app.datasource.index';
import { app_custom_asn_order_get_vendorLotService } from './app.datasource.index';
import { app_custom_asn_order_lotsService } from './app.datasource.index';
import { app_custom_asn_order_order_linesService } from './app.datasource.index';
import { app_custom_asn_order_shipment_linesService } from './app.datasource.index';
import { app_custom_ds_asn_order_editorService } from './app.datasource.index';
import { app_custom_ds_asn_order_lot_has_inventoryService } from './app.datasource.index';
import { app_custom_ds_asn_orderlines_gridService } from './app.datasource.index';
import { app_custom_ds_contact_addresses_gridService } from './app.datasource.index';
import { app_custom_ds_dock_appointment_editorService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_gridService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_total_pallets_widgetService } from './app.datasource.index';
import { app_custom_ds_find_first_completed_receiving_taskService } from './app.datasource.index';
import { app_custom_ds_get_completed_inspection_tasks_by_shipping_container_idService } from './app.datasource.index';
import { app_custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateIdService } from './app.datasource.index';
import { app_custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateIdService } from './app.datasource.index';
import { app_custom_ds_get_completed_putaway_tasks_by_shipmentIdService } from './app.datasource.index';
import { app_custom_ds_get_container_size_by_containerIdService } from './app.datasource.index';
import { app_custom_ds_get_container_size_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_dock_appointment_by_appointmentIdService } from './app.datasource.index';
import { app_custom_ds_get_dock_appointment_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_dock_appointment_udfs_by_dockaAppointmentIdService } from './app.datasource.index';
import { app_custom_ds_get_dock_appointments_with_associated_linked_appointmentsService } from './app.datasource.index';
import { app_custom_ds_get_fda_order_by_awb_entrynumberService } from './app.datasource.index';
import { app_custom_ds_get_licenseplate_udfs_by_licenseplateIdService } from './app.datasource.index';
import { app_custom_ds_get_licenseplatecontents_by_shippingContainerIdService } from './app.datasource.index';
import { app_custom_ds_get_material_gtin_by_materialIdService } from './app.datasource.index';
import { app_custom_ds_get_materials_by_gtinService } from './app.datasource.index';
import { app_custom_ds_get_open_picking_tasks_by_shipping_container_idService } from './app.datasource.index';
import { app_custom_ds_get_order_address_by_orderId_top1Service } from './app.datasource.index';
import { app_custom_ds_get_order_addresses_by_shipping_container_idService } from './app.datasource.index';
import { app_custom_ds_get_order_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_by_orderIdsService } from './app.datasource.index';
import { app_custom_ds_get_order_class_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_from_shipping_containerService } from './app.datasource.index';
import { app_custom_ds_get_order_owner_contact_top1Service } from './app.datasource.index';
import { app_custom_ds_get_order_ship_from_account_vs_order_addressService } from './app.datasource.index';
import { app_custom_ds_get_order_ship_to_account_vs_order_addressService } from './app.datasource.index';
import { app_custom_ds_get_order_status_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_udfs_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_warehouse_contact_top1Service } from './app.datasource.index';
import { app_custom_ds_get_orderline_total_picked_units_by_orderId_linenumberService } from './app.datasource.index';
import { app_custom_ds_get_orderlines_by_orderId_top1Service } from './app.datasource.index';
import { app_custom_ds_get_original_default_account_idService } from './app.datasource.index';
import { app_custom_ds_get_parent_appointment_by_linkedDockAppointmentIdService } from './app.datasource.index';
import { app_custom_ds_get_picking_tasks_by_shipping_container_idService } from './app.datasource.index';
import { app_custom_ds_get_serialnumbers_by_lot_packaging_licenseplateService } from './app.datasource.index';
import { app_custom_ds_get_shipmentLines_by_orderId_top1Service } from './app.datasource.index';
import { app_custom_ds_get_shipment_contents_by_shipmentIdsService } from './app.datasource.index';
import { app_custom_ds_get_shipment_from_shippingcontainersService } from './app.datasource.index';
import { app_custom_ds_get_shipments_aggregated_shipping_containersService } from './app.datasource.index';
import { app_custom_ds_get_shipments_aggregated_tasksService } from './app.datasource.index';
import { app_custom_ds_get_shipments_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_shipping_address_from_accountService } from './app.datasource.index';
import { app_custom_ds_get_shipping_address_from_shipping_containerService } from './app.datasource.index';
import { app_custom_ds_get_shipping_container_by_shippingContainerIdService } from './app.datasource.index';
import { app_custom_ds_get_shipping_containers_tasksService } from './app.datasource.index';
import { app_custom_ds_get_start_pack_verification_tasks_by_shippingContainerIdService } from './app.datasource.index';
import { app_custom_ds_get_validation_tasks_by_licenseplateIdService } from './app.datasource.index';
import { app_custom_ds_get_warehouse_addr_by_accService } from './app.datasource.index';
import { app_custom_ds_get_warehouse_addr_by_shipping_containerService } from './app.datasource.index';
import { app_custom_ds_inbound_orders_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_count_editorService } from './app.datasource.index';
import { app_custom_ds_inventory_licenseplate_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_transfer_lines_gridService } from './app.datasource.index';
import { app_custom_ds_licenseplate_statuses_ddService } from './app.datasource.index';
import { app_custom_ds_load_container_editorService } from './app.datasource.index';
import { app_custom_ds_location_editorService } from './app.datasource.index';
import { app_custom_ds_location_import_gridService } from './app.datasource.index';
import { app_custom_ds_location_statuses_ddService } from './app.datasource.index';
import { app_custom_ds_lot_statuses_ddService } from './app.datasource.index';
import { app_custom_ds_material_selector_batchedService } from './app.datasource.index';
import { app_custom_ds_material_selector_gridService } from './app.datasource.index';
import { app_custom_ds_material_statuses_ddService } from './app.datasource.index';
import { app_custom_ds_materials_udf_gridService } from './app.datasource.index';
import { app_custom_ds_order_dock_appointment_completeService } from './app.datasource.index';
import { app_custom_ds_order_total_pallet_countService } from './app.datasource.index';
import { app_custom_ds_orderaddresses_gridService } from './app.datasource.index';
import { app_custom_ds_orders_by_load_container_gridService } from './app.datasource.index';
import { app_custom_ds_outbound_labelsService } from './app.datasource.index';
import { app_custom_ds_outbound_orderclasses_ddService } from './app.datasource.index';
import { app_custom_ds_ownersaccounts_gridService } from './app.datasource.index';
import { app_custom_ds_projects_ddService } from './app.datasource.index';
import { app_custom_ds_purchase_order_editorService } from './app.datasource.index';
import { app_custom_ds_purchase_order_lines_gridService } from './app.datasource.index';
import { app_custom_ds_sales_order_editorService } from './app.datasource.index';
import { app_custom_ds_sales_order_lines_gridService } from './app.datasource.index';
import { app_custom_ds_sales_order_picking_gridService } from './app.datasource.index';
import { app_custom_ds_serialnumber_statuses_ddService } from './app.datasource.index';
import { app_custom_ds_survey_appointmentService } from './app.datasource.index';
import { app_custom_ds_survey_orderService } from './app.datasource.index';
import { app_custom_ds_warehouses_ddService } from './app.datasource.index';
import { app_custom_get_location_container_by_locationIdService } from './app.datasource.index';
import { app_ds_find_lotsService } from './app.datasource.index';
import { app_ds_get_completed_putaway_task_by_licenseplateId_shipmentIdService } from './app.datasource.index';
import { app_ncustom_ds_order_dock_appointment_completeService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.Cartonization = this.injector.get(Cartonization_DatasourceService);
    this.LaborManagement = this.injector.get(LaborManagement_DatasourceService);
    this.LoadContainers = this.injector.get(LoadContainers_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.PackVerification = this.injector.get(PackVerification_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.Replenishments = this.injector.get(Replenishments_DatasourceService);
    this.ShippingContainers = this.injector.get(ShippingContainers_DatasourceService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_DatasourceService);
    this.Waves = this.injector.get(Waves_DatasourceService);
    this.WorkOrders = this.injector.get(WorkOrders_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_DatasourceService);
    this.SerialNumbers = this.injector.get(SerialNumbers_DatasourceService);
    this.InventoryCounts = this.injector.get(InventoryCounts_DatasourceService);
    this.AsnOrders = this.injector.get(AsnOrders_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.LocusRobotics = this.injector.get(LocusRobotics_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public Cartonization: Cartonization_DatasourceService;
    public LaborManagement: LaborManagement_DatasourceService;
    public LoadContainers: LoadContainers_DatasourceService;
    public Materials: Materials_DatasourceService;
    public PackVerification: PackVerification_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public Replenishments: Replenishments_DatasourceService;
    public ShippingContainers: ShippingContainers_DatasourceService;
    public TemperatureReadings: TemperatureReadings_DatasourceService;
    public Waves: Waves_DatasourceService;
    public WorkOrders: WorkOrders_DatasourceService;
    public Lots: Lots_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public ExcelLocationImport: ExcelLocationImport_DatasourceService;
    public SerialNumbers: SerialNumbers_DatasourceService;
    public InventoryCounts: InventoryCounts_DatasourceService;
    public AsnOrders: AsnOrders_DatasourceService;
    public Owners: Owners_DatasourceService;
    public WarehouseTransfers: WarehouseTransfers_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Reports: Reports_DatasourceService;
    public Locations: Locations_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public LocusRobotics: LocusRobotics_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_asn_order_get_lot: app_custom_asn_order_get_lotService;
  public get custom_asn_order_get_lot(): app_custom_asn_order_get_lotService {
    if(!this._custom_asn_order_get_lot) {
      this._custom_asn_order_get_lot = this.injector.get(app_custom_asn_order_get_lotService);
    }
    return this._custom_asn_order_get_lot;
  }
  private _custom_asn_order_get_lots: app_custom_asn_order_get_lotsService;
  public get custom_asn_order_get_lots(): app_custom_asn_order_get_lotsService {
    if(!this._custom_asn_order_get_lots) {
      this._custom_asn_order_get_lots = this.injector.get(app_custom_asn_order_get_lotsService);
    }
    return this._custom_asn_order_get_lots;
  }
  private _custom_asn_order_get_material: app_custom_asn_order_get_materialService;
  public get custom_asn_order_get_material(): app_custom_asn_order_get_materialService {
    if(!this._custom_asn_order_get_material) {
      this._custom_asn_order_get_material = this.injector.get(app_custom_asn_order_get_materialService);
    }
    return this._custom_asn_order_get_material;
  }
  private _custom_asn_order_get_material_packaging: app_custom_asn_order_get_material_packagingService;
  public get custom_asn_order_get_material_packaging(): app_custom_asn_order_get_material_packagingService {
    if(!this._custom_asn_order_get_material_packaging) {
      this._custom_asn_order_get_material_packaging = this.injector.get(app_custom_asn_order_get_material_packagingService);
    }
    return this._custom_asn_order_get_material_packaging;
  }
  private _custom_asn_order_get_materials: app_custom_asn_order_get_materialsService;
  public get custom_asn_order_get_materials(): app_custom_asn_order_get_materialsService {
    if(!this._custom_asn_order_get_materials) {
      this._custom_asn_order_get_materials = this.injector.get(app_custom_asn_order_get_materialsService);
    }
    return this._custom_asn_order_get_materials;
  }
  private _custom_asn_order_get_orderValidationResults: app_custom_asn_order_get_orderValidationResultsService;
  public get custom_asn_order_get_orderValidationResults(): app_custom_asn_order_get_orderValidationResultsService {
    if(!this._custom_asn_order_get_orderValidationResults) {
      this._custom_asn_order_get_orderValidationResults = this.injector.get(app_custom_asn_order_get_orderValidationResultsService);
    }
    return this._custom_asn_order_get_orderValidationResults;
  }
  private _custom_asn_order_get_shipmentOrderLookup: app_custom_asn_order_get_shipmentOrderLookupService;
  public get custom_asn_order_get_shipmentOrderLookup(): app_custom_asn_order_get_shipmentOrderLookupService {
    if(!this._custom_asn_order_get_shipmentOrderLookup) {
      this._custom_asn_order_get_shipmentOrderLookup = this.injector.get(app_custom_asn_order_get_shipmentOrderLookupService);
    }
    return this._custom_asn_order_get_shipmentOrderLookup;
  }
  private _custom_asn_order_get_target_material: app_custom_asn_order_get_target_materialService;
  public get custom_asn_order_get_target_material(): app_custom_asn_order_get_target_materialService {
    if(!this._custom_asn_order_get_target_material) {
      this._custom_asn_order_get_target_material = this.injector.get(app_custom_asn_order_get_target_materialService);
    }
    return this._custom_asn_order_get_target_material;
  }
  private _custom_asn_order_get_tasks: app_custom_asn_order_get_tasksService;
  public get custom_asn_order_get_tasks(): app_custom_asn_order_get_tasksService {
    if(!this._custom_asn_order_get_tasks) {
      this._custom_asn_order_get_tasks = this.injector.get(app_custom_asn_order_get_tasksService);
    }
    return this._custom_asn_order_get_tasks;
  }
  private _custom_asn_order_get_vendorLot: app_custom_asn_order_get_vendorLotService;
  public get custom_asn_order_get_vendorLot(): app_custom_asn_order_get_vendorLotService {
    if(!this._custom_asn_order_get_vendorLot) {
      this._custom_asn_order_get_vendorLot = this.injector.get(app_custom_asn_order_get_vendorLotService);
    }
    return this._custom_asn_order_get_vendorLot;
  }
  private _custom_asn_order_lots: app_custom_asn_order_lotsService;
  public get custom_asn_order_lots(): app_custom_asn_order_lotsService {
    if(!this._custom_asn_order_lots) {
      this._custom_asn_order_lots = this.injector.get(app_custom_asn_order_lotsService);
    }
    return this._custom_asn_order_lots;
  }
  private _custom_asn_order_order_lines: app_custom_asn_order_order_linesService;
  public get custom_asn_order_order_lines(): app_custom_asn_order_order_linesService {
    if(!this._custom_asn_order_order_lines) {
      this._custom_asn_order_order_lines = this.injector.get(app_custom_asn_order_order_linesService);
    }
    return this._custom_asn_order_order_lines;
  }
  private _custom_asn_order_shipment_lines: app_custom_asn_order_shipment_linesService;
  public get custom_asn_order_shipment_lines(): app_custom_asn_order_shipment_linesService {
    if(!this._custom_asn_order_shipment_lines) {
      this._custom_asn_order_shipment_lines = this.injector.get(app_custom_asn_order_shipment_linesService);
    }
    return this._custom_asn_order_shipment_lines;
  }
  private _custom_ds_asn_order_editor: app_custom_ds_asn_order_editorService;
  public get custom_ds_asn_order_editor(): app_custom_ds_asn_order_editorService {
    if(!this._custom_ds_asn_order_editor) {
      this._custom_ds_asn_order_editor = this.injector.get(app_custom_ds_asn_order_editorService);
    }
    return this._custom_ds_asn_order_editor;
  }
  private _custom_ds_asn_order_lot_has_inventory: app_custom_ds_asn_order_lot_has_inventoryService;
  public get custom_ds_asn_order_lot_has_inventory(): app_custom_ds_asn_order_lot_has_inventoryService {
    if(!this._custom_ds_asn_order_lot_has_inventory) {
      this._custom_ds_asn_order_lot_has_inventory = this.injector.get(app_custom_ds_asn_order_lot_has_inventoryService);
    }
    return this._custom_ds_asn_order_lot_has_inventory;
  }
  private _custom_ds_asn_orderlines_grid: app_custom_ds_asn_orderlines_gridService;
  public get custom_ds_asn_orderlines_grid(): app_custom_ds_asn_orderlines_gridService {
    if(!this._custom_ds_asn_orderlines_grid) {
      this._custom_ds_asn_orderlines_grid = this.injector.get(app_custom_ds_asn_orderlines_gridService);
    }
    return this._custom_ds_asn_orderlines_grid;
  }
  private _custom_ds_contact_addresses_grid: app_custom_ds_contact_addresses_gridService;
  public get custom_ds_contact_addresses_grid(): app_custom_ds_contact_addresses_gridService {
    if(!this._custom_ds_contact_addresses_grid) {
      this._custom_ds_contact_addresses_grid = this.injector.get(app_custom_ds_contact_addresses_gridService);
    }
    return this._custom_ds_contact_addresses_grid;
  }
  private _custom_ds_dock_appointment_editor: app_custom_ds_dock_appointment_editorService;
  public get custom_ds_dock_appointment_editor(): app_custom_ds_dock_appointment_editorService {
    if(!this._custom_ds_dock_appointment_editor) {
      this._custom_ds_dock_appointment_editor = this.injector.get(app_custom_ds_dock_appointment_editorService);
    }
    return this._custom_ds_dock_appointment_editor;
  }
  private _custom_ds_dock_appointments_grid: app_custom_ds_dock_appointments_gridService;
  public get custom_ds_dock_appointments_grid(): app_custom_ds_dock_appointments_gridService {
    if(!this._custom_ds_dock_appointments_grid) {
      this._custom_ds_dock_appointments_grid = this.injector.get(app_custom_ds_dock_appointments_gridService);
    }
    return this._custom_ds_dock_appointments_grid;
  }
  private _custom_ds_dock_appointments_total_pallets_widget: app_custom_ds_dock_appointments_total_pallets_widgetService;
  public get custom_ds_dock_appointments_total_pallets_widget(): app_custom_ds_dock_appointments_total_pallets_widgetService {
    if(!this._custom_ds_dock_appointments_total_pallets_widget) {
      this._custom_ds_dock_appointments_total_pallets_widget = this.injector.get(app_custom_ds_dock_appointments_total_pallets_widgetService);
    }
    return this._custom_ds_dock_appointments_total_pallets_widget;
  }
  private _custom_ds_find_first_completed_receiving_task: app_custom_ds_find_first_completed_receiving_taskService;
  public get custom_ds_find_first_completed_receiving_task(): app_custom_ds_find_first_completed_receiving_taskService {
    if(!this._custom_ds_find_first_completed_receiving_task) {
      this._custom_ds_find_first_completed_receiving_task = this.injector.get(app_custom_ds_find_first_completed_receiving_taskService);
    }
    return this._custom_ds_find_first_completed_receiving_task;
  }
  private _custom_ds_get_completed_inspection_tasks_by_shipping_container_id: app_custom_ds_get_completed_inspection_tasks_by_shipping_container_idService;
  public get custom_ds_get_completed_inspection_tasks_by_shipping_container_id(): app_custom_ds_get_completed_inspection_tasks_by_shipping_container_idService {
    if(!this._custom_ds_get_completed_inspection_tasks_by_shipping_container_id) {
      this._custom_ds_get_completed_inspection_tasks_by_shipping_container_id = this.injector.get(app_custom_ds_get_completed_inspection_tasks_by_shipping_container_idService);
    }
    return this._custom_ds_get_completed_inspection_tasks_by_shipping_container_id;
  }
  private _custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateId: app_custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateIdService;
  public get custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateId(): app_custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateIdService {
    if(!this._custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateId) {
      this._custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateId = this.injector.get(app_custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateIdService);
    }
    return this._custom_ds_get_completed_inventorymove_tasks_by_chainhead_licenseplateId;
  }
  private _custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateId: app_custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateIdService;
  public get custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateId(): app_custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateIdService {
    if(!this._custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateId) {
      this._custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateId = this.injector.get(app_custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateIdService);
    }
    return this._custom_ds_get_completed_inventorymove_tasks_by_sourcelicenseplateId_targetlicenseplateId;
  }
  private _custom_ds_get_completed_putaway_tasks_by_shipmentId: app_custom_ds_get_completed_putaway_tasks_by_shipmentIdService;
  public get custom_ds_get_completed_putaway_tasks_by_shipmentId(): app_custom_ds_get_completed_putaway_tasks_by_shipmentIdService {
    if(!this._custom_ds_get_completed_putaway_tasks_by_shipmentId) {
      this._custom_ds_get_completed_putaway_tasks_by_shipmentId = this.injector.get(app_custom_ds_get_completed_putaway_tasks_by_shipmentIdService);
    }
    return this._custom_ds_get_completed_putaway_tasks_by_shipmentId;
  }
  private _custom_ds_get_container_size_by_containerId: app_custom_ds_get_container_size_by_containerIdService;
  public get custom_ds_get_container_size_by_containerId(): app_custom_ds_get_container_size_by_containerIdService {
    if(!this._custom_ds_get_container_size_by_containerId) {
      this._custom_ds_get_container_size_by_containerId = this.injector.get(app_custom_ds_get_container_size_by_containerIdService);
    }
    return this._custom_ds_get_container_size_by_containerId;
  }
  private _custom_ds_get_container_size_by_name: app_custom_ds_get_container_size_by_nameService;
  public get custom_ds_get_container_size_by_name(): app_custom_ds_get_container_size_by_nameService {
    if(!this._custom_ds_get_container_size_by_name) {
      this._custom_ds_get_container_size_by_name = this.injector.get(app_custom_ds_get_container_size_by_nameService);
    }
    return this._custom_ds_get_container_size_by_name;
  }
  private _custom_ds_get_dock_appointment_by_appointmentId: app_custom_ds_get_dock_appointment_by_appointmentIdService;
  public get custom_ds_get_dock_appointment_by_appointmentId(): app_custom_ds_get_dock_appointment_by_appointmentIdService {
    if(!this._custom_ds_get_dock_appointment_by_appointmentId) {
      this._custom_ds_get_dock_appointment_by_appointmentId = this.injector.get(app_custom_ds_get_dock_appointment_by_appointmentIdService);
    }
    return this._custom_ds_get_dock_appointment_by_appointmentId;
  }
  private _custom_ds_get_dock_appointment_by_orderId: app_custom_ds_get_dock_appointment_by_orderIdService;
  public get custom_ds_get_dock_appointment_by_orderId(): app_custom_ds_get_dock_appointment_by_orderIdService {
    if(!this._custom_ds_get_dock_appointment_by_orderId) {
      this._custom_ds_get_dock_appointment_by_orderId = this.injector.get(app_custom_ds_get_dock_appointment_by_orderIdService);
    }
    return this._custom_ds_get_dock_appointment_by_orderId;
  }
  private _custom_ds_get_dock_appointment_udfs_by_dockaAppointmentId: app_custom_ds_get_dock_appointment_udfs_by_dockaAppointmentIdService;
  public get custom_ds_get_dock_appointment_udfs_by_dockaAppointmentId(): app_custom_ds_get_dock_appointment_udfs_by_dockaAppointmentIdService {
    if(!this._custom_ds_get_dock_appointment_udfs_by_dockaAppointmentId) {
      this._custom_ds_get_dock_appointment_udfs_by_dockaAppointmentId = this.injector.get(app_custom_ds_get_dock_appointment_udfs_by_dockaAppointmentIdService);
    }
    return this._custom_ds_get_dock_appointment_udfs_by_dockaAppointmentId;
  }
  private _custom_ds_get_dock_appointments_with_associated_linked_appointments: app_custom_ds_get_dock_appointments_with_associated_linked_appointmentsService;
  public get custom_ds_get_dock_appointments_with_associated_linked_appointments(): app_custom_ds_get_dock_appointments_with_associated_linked_appointmentsService {
    if(!this._custom_ds_get_dock_appointments_with_associated_linked_appointments) {
      this._custom_ds_get_dock_appointments_with_associated_linked_appointments = this.injector.get(app_custom_ds_get_dock_appointments_with_associated_linked_appointmentsService);
    }
    return this._custom_ds_get_dock_appointments_with_associated_linked_appointments;
  }
  private _custom_ds_get_fda_order_by_awb_entrynumber: app_custom_ds_get_fda_order_by_awb_entrynumberService;
  public get custom_ds_get_fda_order_by_awb_entrynumber(): app_custom_ds_get_fda_order_by_awb_entrynumberService {
    if(!this._custom_ds_get_fda_order_by_awb_entrynumber) {
      this._custom_ds_get_fda_order_by_awb_entrynumber = this.injector.get(app_custom_ds_get_fda_order_by_awb_entrynumberService);
    }
    return this._custom_ds_get_fda_order_by_awb_entrynumber;
  }
  private _custom_ds_get_licenseplate_udfs_by_licenseplateId: app_custom_ds_get_licenseplate_udfs_by_licenseplateIdService;
  public get custom_ds_get_licenseplate_udfs_by_licenseplateId(): app_custom_ds_get_licenseplate_udfs_by_licenseplateIdService {
    if(!this._custom_ds_get_licenseplate_udfs_by_licenseplateId) {
      this._custom_ds_get_licenseplate_udfs_by_licenseplateId = this.injector.get(app_custom_ds_get_licenseplate_udfs_by_licenseplateIdService);
    }
    return this._custom_ds_get_licenseplate_udfs_by_licenseplateId;
  }
  private _custom_ds_get_licenseplatecontents_by_shippingContainerId: app_custom_ds_get_licenseplatecontents_by_shippingContainerIdService;
  public get custom_ds_get_licenseplatecontents_by_shippingContainerId(): app_custom_ds_get_licenseplatecontents_by_shippingContainerIdService {
    if(!this._custom_ds_get_licenseplatecontents_by_shippingContainerId) {
      this._custom_ds_get_licenseplatecontents_by_shippingContainerId = this.injector.get(app_custom_ds_get_licenseplatecontents_by_shippingContainerIdService);
    }
    return this._custom_ds_get_licenseplatecontents_by_shippingContainerId;
  }
  private _custom_ds_get_material_gtin_by_materialId: app_custom_ds_get_material_gtin_by_materialIdService;
  public get custom_ds_get_material_gtin_by_materialId(): app_custom_ds_get_material_gtin_by_materialIdService {
    if(!this._custom_ds_get_material_gtin_by_materialId) {
      this._custom_ds_get_material_gtin_by_materialId = this.injector.get(app_custom_ds_get_material_gtin_by_materialIdService);
    }
    return this._custom_ds_get_material_gtin_by_materialId;
  }
  private _custom_ds_get_materials_by_gtin: app_custom_ds_get_materials_by_gtinService;
  public get custom_ds_get_materials_by_gtin(): app_custom_ds_get_materials_by_gtinService {
    if(!this._custom_ds_get_materials_by_gtin) {
      this._custom_ds_get_materials_by_gtin = this.injector.get(app_custom_ds_get_materials_by_gtinService);
    }
    return this._custom_ds_get_materials_by_gtin;
  }
  private _custom_ds_get_open_picking_tasks_by_shipping_container_id: app_custom_ds_get_open_picking_tasks_by_shipping_container_idService;
  public get custom_ds_get_open_picking_tasks_by_shipping_container_id(): app_custom_ds_get_open_picking_tasks_by_shipping_container_idService {
    if(!this._custom_ds_get_open_picking_tasks_by_shipping_container_id) {
      this._custom_ds_get_open_picking_tasks_by_shipping_container_id = this.injector.get(app_custom_ds_get_open_picking_tasks_by_shipping_container_idService);
    }
    return this._custom_ds_get_open_picking_tasks_by_shipping_container_id;
  }
  private _custom_ds_get_order_address_by_orderId_top1: app_custom_ds_get_order_address_by_orderId_top1Service;
  public get custom_ds_get_order_address_by_orderId_top1(): app_custom_ds_get_order_address_by_orderId_top1Service {
    if(!this._custom_ds_get_order_address_by_orderId_top1) {
      this._custom_ds_get_order_address_by_orderId_top1 = this.injector.get(app_custom_ds_get_order_address_by_orderId_top1Service);
    }
    return this._custom_ds_get_order_address_by_orderId_top1;
  }
  private _custom_ds_get_order_addresses_by_shipping_container_id: app_custom_ds_get_order_addresses_by_shipping_container_idService;
  public get custom_ds_get_order_addresses_by_shipping_container_id(): app_custom_ds_get_order_addresses_by_shipping_container_idService {
    if(!this._custom_ds_get_order_addresses_by_shipping_container_id) {
      this._custom_ds_get_order_addresses_by_shipping_container_id = this.injector.get(app_custom_ds_get_order_addresses_by_shipping_container_idService);
    }
    return this._custom_ds_get_order_addresses_by_shipping_container_id;
  }
  private _custom_ds_get_order_by_orderId: app_custom_ds_get_order_by_orderIdService;
  public get custom_ds_get_order_by_orderId(): app_custom_ds_get_order_by_orderIdService {
    if(!this._custom_ds_get_order_by_orderId) {
      this._custom_ds_get_order_by_orderId = this.injector.get(app_custom_ds_get_order_by_orderIdService);
    }
    return this._custom_ds_get_order_by_orderId;
  }
  private _custom_ds_get_order_by_orderIds: app_custom_ds_get_order_by_orderIdsService;
  public get custom_ds_get_order_by_orderIds(): app_custom_ds_get_order_by_orderIdsService {
    if(!this._custom_ds_get_order_by_orderIds) {
      this._custom_ds_get_order_by_orderIds = this.injector.get(app_custom_ds_get_order_by_orderIdsService);
    }
    return this._custom_ds_get_order_by_orderIds;
  }
  private _custom_ds_get_order_class_by_orderId: app_custom_ds_get_order_class_by_orderIdService;
  public get custom_ds_get_order_class_by_orderId(): app_custom_ds_get_order_class_by_orderIdService {
    if(!this._custom_ds_get_order_class_by_orderId) {
      this._custom_ds_get_order_class_by_orderId = this.injector.get(app_custom_ds_get_order_class_by_orderIdService);
    }
    return this._custom_ds_get_order_class_by_orderId;
  }
  private _custom_ds_get_order_from_shipping_container: app_custom_ds_get_order_from_shipping_containerService;
  public get custom_ds_get_order_from_shipping_container(): app_custom_ds_get_order_from_shipping_containerService {
    if(!this._custom_ds_get_order_from_shipping_container) {
      this._custom_ds_get_order_from_shipping_container = this.injector.get(app_custom_ds_get_order_from_shipping_containerService);
    }
    return this._custom_ds_get_order_from_shipping_container;
  }
  private _custom_ds_get_order_owner_contact_top1: app_custom_ds_get_order_owner_contact_top1Service;
  public get custom_ds_get_order_owner_contact_top1(): app_custom_ds_get_order_owner_contact_top1Service {
    if(!this._custom_ds_get_order_owner_contact_top1) {
      this._custom_ds_get_order_owner_contact_top1 = this.injector.get(app_custom_ds_get_order_owner_contact_top1Service);
    }
    return this._custom_ds_get_order_owner_contact_top1;
  }
  private _custom_ds_get_order_ship_from_account_vs_order_address: app_custom_ds_get_order_ship_from_account_vs_order_addressService;
  public get custom_ds_get_order_ship_from_account_vs_order_address(): app_custom_ds_get_order_ship_from_account_vs_order_addressService {
    if(!this._custom_ds_get_order_ship_from_account_vs_order_address) {
      this._custom_ds_get_order_ship_from_account_vs_order_address = this.injector.get(app_custom_ds_get_order_ship_from_account_vs_order_addressService);
    }
    return this._custom_ds_get_order_ship_from_account_vs_order_address;
  }
  private _custom_ds_get_order_ship_to_account_vs_order_address: app_custom_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_ds_get_order_ship_to_account_vs_order_address(): app_custom_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_ds_get_order_ship_to_account_vs_order_address = this.injector.get(app_custom_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_ds_get_order_status_by_orderId: app_custom_ds_get_order_status_by_orderIdService;
  public get custom_ds_get_order_status_by_orderId(): app_custom_ds_get_order_status_by_orderIdService {
    if(!this._custom_ds_get_order_status_by_orderId) {
      this._custom_ds_get_order_status_by_orderId = this.injector.get(app_custom_ds_get_order_status_by_orderIdService);
    }
    return this._custom_ds_get_order_status_by_orderId;
  }
  private _custom_ds_get_order_udfs_by_orderId: app_custom_ds_get_order_udfs_by_orderIdService;
  public get custom_ds_get_order_udfs_by_orderId(): app_custom_ds_get_order_udfs_by_orderIdService {
    if(!this._custom_ds_get_order_udfs_by_orderId) {
      this._custom_ds_get_order_udfs_by_orderId = this.injector.get(app_custom_ds_get_order_udfs_by_orderIdService);
    }
    return this._custom_ds_get_order_udfs_by_orderId;
  }
  private _custom_ds_get_order_warehouse_contact_top1: app_custom_ds_get_order_warehouse_contact_top1Service;
  public get custom_ds_get_order_warehouse_contact_top1(): app_custom_ds_get_order_warehouse_contact_top1Service {
    if(!this._custom_ds_get_order_warehouse_contact_top1) {
      this._custom_ds_get_order_warehouse_contact_top1 = this.injector.get(app_custom_ds_get_order_warehouse_contact_top1Service);
    }
    return this._custom_ds_get_order_warehouse_contact_top1;
  }
  private _custom_ds_get_orderline_total_picked_units_by_orderId_linenumber: app_custom_ds_get_orderline_total_picked_units_by_orderId_linenumberService;
  public get custom_ds_get_orderline_total_picked_units_by_orderId_linenumber(): app_custom_ds_get_orderline_total_picked_units_by_orderId_linenumberService {
    if(!this._custom_ds_get_orderline_total_picked_units_by_orderId_linenumber) {
      this._custom_ds_get_orderline_total_picked_units_by_orderId_linenumber = this.injector.get(app_custom_ds_get_orderline_total_picked_units_by_orderId_linenumberService);
    }
    return this._custom_ds_get_orderline_total_picked_units_by_orderId_linenumber;
  }
  private _custom_ds_get_orderlines_by_orderId_top1: app_custom_ds_get_orderlines_by_orderId_top1Service;
  public get custom_ds_get_orderlines_by_orderId_top1(): app_custom_ds_get_orderlines_by_orderId_top1Service {
    if(!this._custom_ds_get_orderlines_by_orderId_top1) {
      this._custom_ds_get_orderlines_by_orderId_top1 = this.injector.get(app_custom_ds_get_orderlines_by_orderId_top1Service);
    }
    return this._custom_ds_get_orderlines_by_orderId_top1;
  }
  private _custom_ds_get_original_default_account_id: app_custom_ds_get_original_default_account_idService;
  public get custom_ds_get_original_default_account_id(): app_custom_ds_get_original_default_account_idService {
    if(!this._custom_ds_get_original_default_account_id) {
      this._custom_ds_get_original_default_account_id = this.injector.get(app_custom_ds_get_original_default_account_idService);
    }
    return this._custom_ds_get_original_default_account_id;
  }
  private _custom_ds_get_parent_appointment_by_linkedDockAppointmentId: app_custom_ds_get_parent_appointment_by_linkedDockAppointmentIdService;
  public get custom_ds_get_parent_appointment_by_linkedDockAppointmentId(): app_custom_ds_get_parent_appointment_by_linkedDockAppointmentIdService {
    if(!this._custom_ds_get_parent_appointment_by_linkedDockAppointmentId) {
      this._custom_ds_get_parent_appointment_by_linkedDockAppointmentId = this.injector.get(app_custom_ds_get_parent_appointment_by_linkedDockAppointmentIdService);
    }
    return this._custom_ds_get_parent_appointment_by_linkedDockAppointmentId;
  }
  private _custom_ds_get_picking_tasks_by_shipping_container_id: app_custom_ds_get_picking_tasks_by_shipping_container_idService;
  public get custom_ds_get_picking_tasks_by_shipping_container_id(): app_custom_ds_get_picking_tasks_by_shipping_container_idService {
    if(!this._custom_ds_get_picking_tasks_by_shipping_container_id) {
      this._custom_ds_get_picking_tasks_by_shipping_container_id = this.injector.get(app_custom_ds_get_picking_tasks_by_shipping_container_idService);
    }
    return this._custom_ds_get_picking_tasks_by_shipping_container_id;
  }
  private _custom_ds_get_serialnumbers_by_lot_packaging_licenseplate: app_custom_ds_get_serialnumbers_by_lot_packaging_licenseplateService;
  public get custom_ds_get_serialnumbers_by_lot_packaging_licenseplate(): app_custom_ds_get_serialnumbers_by_lot_packaging_licenseplateService {
    if(!this._custom_ds_get_serialnumbers_by_lot_packaging_licenseplate) {
      this._custom_ds_get_serialnumbers_by_lot_packaging_licenseplate = this.injector.get(app_custom_ds_get_serialnumbers_by_lot_packaging_licenseplateService);
    }
    return this._custom_ds_get_serialnumbers_by_lot_packaging_licenseplate;
  }
  private _custom_ds_get_shipmentLines_by_orderId_top1: app_custom_ds_get_shipmentLines_by_orderId_top1Service;
  public get custom_ds_get_shipmentLines_by_orderId_top1(): app_custom_ds_get_shipmentLines_by_orderId_top1Service {
    if(!this._custom_ds_get_shipmentLines_by_orderId_top1) {
      this._custom_ds_get_shipmentLines_by_orderId_top1 = this.injector.get(app_custom_ds_get_shipmentLines_by_orderId_top1Service);
    }
    return this._custom_ds_get_shipmentLines_by_orderId_top1;
  }
  private _custom_ds_get_shipment_contents_by_shipmentIds: app_custom_ds_get_shipment_contents_by_shipmentIdsService;
  public get custom_ds_get_shipment_contents_by_shipmentIds(): app_custom_ds_get_shipment_contents_by_shipmentIdsService {
    if(!this._custom_ds_get_shipment_contents_by_shipmentIds) {
      this._custom_ds_get_shipment_contents_by_shipmentIds = this.injector.get(app_custom_ds_get_shipment_contents_by_shipmentIdsService);
    }
    return this._custom_ds_get_shipment_contents_by_shipmentIds;
  }
  private _custom_ds_get_shipment_from_shippingcontainers: app_custom_ds_get_shipment_from_shippingcontainersService;
  public get custom_ds_get_shipment_from_shippingcontainers(): app_custom_ds_get_shipment_from_shippingcontainersService {
    if(!this._custom_ds_get_shipment_from_shippingcontainers) {
      this._custom_ds_get_shipment_from_shippingcontainers = this.injector.get(app_custom_ds_get_shipment_from_shippingcontainersService);
    }
    return this._custom_ds_get_shipment_from_shippingcontainers;
  }
  private _custom_ds_get_shipments_aggregated_shipping_containers: app_custom_ds_get_shipments_aggregated_shipping_containersService;
  public get custom_ds_get_shipments_aggregated_shipping_containers(): app_custom_ds_get_shipments_aggregated_shipping_containersService {
    if(!this._custom_ds_get_shipments_aggregated_shipping_containers) {
      this._custom_ds_get_shipments_aggregated_shipping_containers = this.injector.get(app_custom_ds_get_shipments_aggregated_shipping_containersService);
    }
    return this._custom_ds_get_shipments_aggregated_shipping_containers;
  }
  private _custom_ds_get_shipments_aggregated_tasks: app_custom_ds_get_shipments_aggregated_tasksService;
  public get custom_ds_get_shipments_aggregated_tasks(): app_custom_ds_get_shipments_aggregated_tasksService {
    if(!this._custom_ds_get_shipments_aggregated_tasks) {
      this._custom_ds_get_shipments_aggregated_tasks = this.injector.get(app_custom_ds_get_shipments_aggregated_tasksService);
    }
    return this._custom_ds_get_shipments_aggregated_tasks;
  }
  private _custom_ds_get_shipments_by_orderId: app_custom_ds_get_shipments_by_orderIdService;
  public get custom_ds_get_shipments_by_orderId(): app_custom_ds_get_shipments_by_orderIdService {
    if(!this._custom_ds_get_shipments_by_orderId) {
      this._custom_ds_get_shipments_by_orderId = this.injector.get(app_custom_ds_get_shipments_by_orderIdService);
    }
    return this._custom_ds_get_shipments_by_orderId;
  }
  private _custom_ds_get_shipping_address_from_account: app_custom_ds_get_shipping_address_from_accountService;
  public get custom_ds_get_shipping_address_from_account(): app_custom_ds_get_shipping_address_from_accountService {
    if(!this._custom_ds_get_shipping_address_from_account) {
      this._custom_ds_get_shipping_address_from_account = this.injector.get(app_custom_ds_get_shipping_address_from_accountService);
    }
    return this._custom_ds_get_shipping_address_from_account;
  }
  private _custom_ds_get_shipping_address_from_shipping_container: app_custom_ds_get_shipping_address_from_shipping_containerService;
  public get custom_ds_get_shipping_address_from_shipping_container(): app_custom_ds_get_shipping_address_from_shipping_containerService {
    if(!this._custom_ds_get_shipping_address_from_shipping_container) {
      this._custom_ds_get_shipping_address_from_shipping_container = this.injector.get(app_custom_ds_get_shipping_address_from_shipping_containerService);
    }
    return this._custom_ds_get_shipping_address_from_shipping_container;
  }
  private _custom_ds_get_shipping_container_by_shippingContainerId: app_custom_ds_get_shipping_container_by_shippingContainerIdService;
  public get custom_ds_get_shipping_container_by_shippingContainerId(): app_custom_ds_get_shipping_container_by_shippingContainerIdService {
    if(!this._custom_ds_get_shipping_container_by_shippingContainerId) {
      this._custom_ds_get_shipping_container_by_shippingContainerId = this.injector.get(app_custom_ds_get_shipping_container_by_shippingContainerIdService);
    }
    return this._custom_ds_get_shipping_container_by_shippingContainerId;
  }
  private _custom_ds_get_shipping_containers_tasks: app_custom_ds_get_shipping_containers_tasksService;
  public get custom_ds_get_shipping_containers_tasks(): app_custom_ds_get_shipping_containers_tasksService {
    if(!this._custom_ds_get_shipping_containers_tasks) {
      this._custom_ds_get_shipping_containers_tasks = this.injector.get(app_custom_ds_get_shipping_containers_tasksService);
    }
    return this._custom_ds_get_shipping_containers_tasks;
  }
  private _custom_ds_get_start_pack_verification_tasks_by_shippingContainerId: app_custom_ds_get_start_pack_verification_tasks_by_shippingContainerIdService;
  public get custom_ds_get_start_pack_verification_tasks_by_shippingContainerId(): app_custom_ds_get_start_pack_verification_tasks_by_shippingContainerIdService {
    if(!this._custom_ds_get_start_pack_verification_tasks_by_shippingContainerId) {
      this._custom_ds_get_start_pack_verification_tasks_by_shippingContainerId = this.injector.get(app_custom_ds_get_start_pack_verification_tasks_by_shippingContainerIdService);
    }
    return this._custom_ds_get_start_pack_verification_tasks_by_shippingContainerId;
  }
  private _custom_ds_get_validation_tasks_by_licenseplateId: app_custom_ds_get_validation_tasks_by_licenseplateIdService;
  public get custom_ds_get_validation_tasks_by_licenseplateId(): app_custom_ds_get_validation_tasks_by_licenseplateIdService {
    if(!this._custom_ds_get_validation_tasks_by_licenseplateId) {
      this._custom_ds_get_validation_tasks_by_licenseplateId = this.injector.get(app_custom_ds_get_validation_tasks_by_licenseplateIdService);
    }
    return this._custom_ds_get_validation_tasks_by_licenseplateId;
  }
  private _custom_ds_get_warehouse_addr_by_acc: app_custom_ds_get_warehouse_addr_by_accService;
  public get custom_ds_get_warehouse_addr_by_acc(): app_custom_ds_get_warehouse_addr_by_accService {
    if(!this._custom_ds_get_warehouse_addr_by_acc) {
      this._custom_ds_get_warehouse_addr_by_acc = this.injector.get(app_custom_ds_get_warehouse_addr_by_accService);
    }
    return this._custom_ds_get_warehouse_addr_by_acc;
  }
  private _custom_ds_get_warehouse_addr_by_shipping_container: app_custom_ds_get_warehouse_addr_by_shipping_containerService;
  public get custom_ds_get_warehouse_addr_by_shipping_container(): app_custom_ds_get_warehouse_addr_by_shipping_containerService {
    if(!this._custom_ds_get_warehouse_addr_by_shipping_container) {
      this._custom_ds_get_warehouse_addr_by_shipping_container = this.injector.get(app_custom_ds_get_warehouse_addr_by_shipping_containerService);
    }
    return this._custom_ds_get_warehouse_addr_by_shipping_container;
  }
  private _custom_ds_inbound_orders_grid: app_custom_ds_inbound_orders_gridService;
  public get custom_ds_inbound_orders_grid(): app_custom_ds_inbound_orders_gridService {
    if(!this._custom_ds_inbound_orders_grid) {
      this._custom_ds_inbound_orders_grid = this.injector.get(app_custom_ds_inbound_orders_gridService);
    }
    return this._custom_ds_inbound_orders_grid;
  }
  private _custom_ds_inventory_count_editor: app_custom_ds_inventory_count_editorService;
  public get custom_ds_inventory_count_editor(): app_custom_ds_inventory_count_editorService {
    if(!this._custom_ds_inventory_count_editor) {
      this._custom_ds_inventory_count_editor = this.injector.get(app_custom_ds_inventory_count_editorService);
    }
    return this._custom_ds_inventory_count_editor;
  }
  private _custom_ds_inventory_licenseplate_grid: app_custom_ds_inventory_licenseplate_gridService;
  public get custom_ds_inventory_licenseplate_grid(): app_custom_ds_inventory_licenseplate_gridService {
    if(!this._custom_ds_inventory_licenseplate_grid) {
      this._custom_ds_inventory_licenseplate_grid = this.injector.get(app_custom_ds_inventory_licenseplate_gridService);
    }
    return this._custom_ds_inventory_licenseplate_grid;
  }
  private _custom_ds_inventory_transfer_lines_grid: app_custom_ds_inventory_transfer_lines_gridService;
  public get custom_ds_inventory_transfer_lines_grid(): app_custom_ds_inventory_transfer_lines_gridService {
    if(!this._custom_ds_inventory_transfer_lines_grid) {
      this._custom_ds_inventory_transfer_lines_grid = this.injector.get(app_custom_ds_inventory_transfer_lines_gridService);
    }
    return this._custom_ds_inventory_transfer_lines_grid;
  }
  private _custom_ds_licenseplate_statuses_dd: app_custom_ds_licenseplate_statuses_ddService;
  public get custom_ds_licenseplate_statuses_dd(): app_custom_ds_licenseplate_statuses_ddService {
    if(!this._custom_ds_licenseplate_statuses_dd) {
      this._custom_ds_licenseplate_statuses_dd = this.injector.get(app_custom_ds_licenseplate_statuses_ddService);
    }
    return this._custom_ds_licenseplate_statuses_dd;
  }
  private _custom_ds_load_container_editor: app_custom_ds_load_container_editorService;
  public get custom_ds_load_container_editor(): app_custom_ds_load_container_editorService {
    if(!this._custom_ds_load_container_editor) {
      this._custom_ds_load_container_editor = this.injector.get(app_custom_ds_load_container_editorService);
    }
    return this._custom_ds_load_container_editor;
  }
  private _custom_ds_location_editor: app_custom_ds_location_editorService;
  public get custom_ds_location_editor(): app_custom_ds_location_editorService {
    if(!this._custom_ds_location_editor) {
      this._custom_ds_location_editor = this.injector.get(app_custom_ds_location_editorService);
    }
    return this._custom_ds_location_editor;
  }
  private _custom_ds_location_import_grid: app_custom_ds_location_import_gridService;
  public get custom_ds_location_import_grid(): app_custom_ds_location_import_gridService {
    if(!this._custom_ds_location_import_grid) {
      this._custom_ds_location_import_grid = this.injector.get(app_custom_ds_location_import_gridService);
    }
    return this._custom_ds_location_import_grid;
  }
  private _custom_ds_location_statuses_dd: app_custom_ds_location_statuses_ddService;
  public get custom_ds_location_statuses_dd(): app_custom_ds_location_statuses_ddService {
    if(!this._custom_ds_location_statuses_dd) {
      this._custom_ds_location_statuses_dd = this.injector.get(app_custom_ds_location_statuses_ddService);
    }
    return this._custom_ds_location_statuses_dd;
  }
  private _custom_ds_lot_statuses_dd: app_custom_ds_lot_statuses_ddService;
  public get custom_ds_lot_statuses_dd(): app_custom_ds_lot_statuses_ddService {
    if(!this._custom_ds_lot_statuses_dd) {
      this._custom_ds_lot_statuses_dd = this.injector.get(app_custom_ds_lot_statuses_ddService);
    }
    return this._custom_ds_lot_statuses_dd;
  }
  private _custom_ds_material_selector_batched: app_custom_ds_material_selector_batchedService;
  public get custom_ds_material_selector_batched(): app_custom_ds_material_selector_batchedService {
    if(!this._custom_ds_material_selector_batched) {
      this._custom_ds_material_selector_batched = this.injector.get(app_custom_ds_material_selector_batchedService);
    }
    return this._custom_ds_material_selector_batched;
  }
  private _custom_ds_material_selector_grid: app_custom_ds_material_selector_gridService;
  public get custom_ds_material_selector_grid(): app_custom_ds_material_selector_gridService {
    if(!this._custom_ds_material_selector_grid) {
      this._custom_ds_material_selector_grid = this.injector.get(app_custom_ds_material_selector_gridService);
    }
    return this._custom_ds_material_selector_grid;
  }
  private _custom_ds_material_statuses_dd: app_custom_ds_material_statuses_ddService;
  public get custom_ds_material_statuses_dd(): app_custom_ds_material_statuses_ddService {
    if(!this._custom_ds_material_statuses_dd) {
      this._custom_ds_material_statuses_dd = this.injector.get(app_custom_ds_material_statuses_ddService);
    }
    return this._custom_ds_material_statuses_dd;
  }
  private _custom_ds_materials_udf_grid: app_custom_ds_materials_udf_gridService;
  public get custom_ds_materials_udf_grid(): app_custom_ds_materials_udf_gridService {
    if(!this._custom_ds_materials_udf_grid) {
      this._custom_ds_materials_udf_grid = this.injector.get(app_custom_ds_materials_udf_gridService);
    }
    return this._custom_ds_materials_udf_grid;
  }
  private _custom_ds_order_dock_appointment_complete: app_custom_ds_order_dock_appointment_completeService;
  public get custom_ds_order_dock_appointment_complete(): app_custom_ds_order_dock_appointment_completeService {
    if(!this._custom_ds_order_dock_appointment_complete) {
      this._custom_ds_order_dock_appointment_complete = this.injector.get(app_custom_ds_order_dock_appointment_completeService);
    }
    return this._custom_ds_order_dock_appointment_complete;
  }
  private _custom_ds_order_total_pallet_count: app_custom_ds_order_total_pallet_countService;
  public get custom_ds_order_total_pallet_count(): app_custom_ds_order_total_pallet_countService {
    if(!this._custom_ds_order_total_pallet_count) {
      this._custom_ds_order_total_pallet_count = this.injector.get(app_custom_ds_order_total_pallet_countService);
    }
    return this._custom_ds_order_total_pallet_count;
  }
  private _custom_ds_orderaddresses_grid: app_custom_ds_orderaddresses_gridService;
  public get custom_ds_orderaddresses_grid(): app_custom_ds_orderaddresses_gridService {
    if(!this._custom_ds_orderaddresses_grid) {
      this._custom_ds_orderaddresses_grid = this.injector.get(app_custom_ds_orderaddresses_gridService);
    }
    return this._custom_ds_orderaddresses_grid;
  }
  private _custom_ds_orders_by_load_container_grid: app_custom_ds_orders_by_load_container_gridService;
  public get custom_ds_orders_by_load_container_grid(): app_custom_ds_orders_by_load_container_gridService {
    if(!this._custom_ds_orders_by_load_container_grid) {
      this._custom_ds_orders_by_load_container_grid = this.injector.get(app_custom_ds_orders_by_load_container_gridService);
    }
    return this._custom_ds_orders_by_load_container_grid;
  }
  private _custom_ds_outbound_labels: app_custom_ds_outbound_labelsService;
  public get custom_ds_outbound_labels(): app_custom_ds_outbound_labelsService {
    if(!this._custom_ds_outbound_labels) {
      this._custom_ds_outbound_labels = this.injector.get(app_custom_ds_outbound_labelsService);
    }
    return this._custom_ds_outbound_labels;
  }
  private _custom_ds_outbound_orderclasses_dd: app_custom_ds_outbound_orderclasses_ddService;
  public get custom_ds_outbound_orderclasses_dd(): app_custom_ds_outbound_orderclasses_ddService {
    if(!this._custom_ds_outbound_orderclasses_dd) {
      this._custom_ds_outbound_orderclasses_dd = this.injector.get(app_custom_ds_outbound_orderclasses_ddService);
    }
    return this._custom_ds_outbound_orderclasses_dd;
  }
  private _custom_ds_ownersaccounts_grid: app_custom_ds_ownersaccounts_gridService;
  public get custom_ds_ownersaccounts_grid(): app_custom_ds_ownersaccounts_gridService {
    if(!this._custom_ds_ownersaccounts_grid) {
      this._custom_ds_ownersaccounts_grid = this.injector.get(app_custom_ds_ownersaccounts_gridService);
    }
    return this._custom_ds_ownersaccounts_grid;
  }
  private _custom_ds_projects_dd: app_custom_ds_projects_ddService;
  public get custom_ds_projects_dd(): app_custom_ds_projects_ddService {
    if(!this._custom_ds_projects_dd) {
      this._custom_ds_projects_dd = this.injector.get(app_custom_ds_projects_ddService);
    }
    return this._custom_ds_projects_dd;
  }
  private _custom_ds_purchase_order_editor: app_custom_ds_purchase_order_editorService;
  public get custom_ds_purchase_order_editor(): app_custom_ds_purchase_order_editorService {
    if(!this._custom_ds_purchase_order_editor) {
      this._custom_ds_purchase_order_editor = this.injector.get(app_custom_ds_purchase_order_editorService);
    }
    return this._custom_ds_purchase_order_editor;
  }
  private _custom_ds_purchase_order_lines_grid: app_custom_ds_purchase_order_lines_gridService;
  public get custom_ds_purchase_order_lines_grid(): app_custom_ds_purchase_order_lines_gridService {
    if(!this._custom_ds_purchase_order_lines_grid) {
      this._custom_ds_purchase_order_lines_grid = this.injector.get(app_custom_ds_purchase_order_lines_gridService);
    }
    return this._custom_ds_purchase_order_lines_grid;
  }
  private _custom_ds_sales_order_editor: app_custom_ds_sales_order_editorService;
  public get custom_ds_sales_order_editor(): app_custom_ds_sales_order_editorService {
    if(!this._custom_ds_sales_order_editor) {
      this._custom_ds_sales_order_editor = this.injector.get(app_custom_ds_sales_order_editorService);
    }
    return this._custom_ds_sales_order_editor;
  }
  private _custom_ds_sales_order_lines_grid: app_custom_ds_sales_order_lines_gridService;
  public get custom_ds_sales_order_lines_grid(): app_custom_ds_sales_order_lines_gridService {
    if(!this._custom_ds_sales_order_lines_grid) {
      this._custom_ds_sales_order_lines_grid = this.injector.get(app_custom_ds_sales_order_lines_gridService);
    }
    return this._custom_ds_sales_order_lines_grid;
  }
  private _custom_ds_sales_order_picking_grid: app_custom_ds_sales_order_picking_gridService;
  public get custom_ds_sales_order_picking_grid(): app_custom_ds_sales_order_picking_gridService {
    if(!this._custom_ds_sales_order_picking_grid) {
      this._custom_ds_sales_order_picking_grid = this.injector.get(app_custom_ds_sales_order_picking_gridService);
    }
    return this._custom_ds_sales_order_picking_grid;
  }
  private _custom_ds_serialnumber_statuses_dd: app_custom_ds_serialnumber_statuses_ddService;
  public get custom_ds_serialnumber_statuses_dd(): app_custom_ds_serialnumber_statuses_ddService {
    if(!this._custom_ds_serialnumber_statuses_dd) {
      this._custom_ds_serialnumber_statuses_dd = this.injector.get(app_custom_ds_serialnumber_statuses_ddService);
    }
    return this._custom_ds_serialnumber_statuses_dd;
  }
  private _custom_ds_survey_appointment: app_custom_ds_survey_appointmentService;
  public get custom_ds_survey_appointment(): app_custom_ds_survey_appointmentService {
    if(!this._custom_ds_survey_appointment) {
      this._custom_ds_survey_appointment = this.injector.get(app_custom_ds_survey_appointmentService);
    }
    return this._custom_ds_survey_appointment;
  }
  private _custom_ds_survey_order: app_custom_ds_survey_orderService;
  public get custom_ds_survey_order(): app_custom_ds_survey_orderService {
    if(!this._custom_ds_survey_order) {
      this._custom_ds_survey_order = this.injector.get(app_custom_ds_survey_orderService);
    }
    return this._custom_ds_survey_order;
  }
  private _custom_ds_warehouses_dd: app_custom_ds_warehouses_ddService;
  public get custom_ds_warehouses_dd(): app_custom_ds_warehouses_ddService {
    if(!this._custom_ds_warehouses_dd) {
      this._custom_ds_warehouses_dd = this.injector.get(app_custom_ds_warehouses_ddService);
    }
    return this._custom_ds_warehouses_dd;
  }
  private _custom_get_location_container_by_locationId: app_custom_get_location_container_by_locationIdService;
  public get custom_get_location_container_by_locationId(): app_custom_get_location_container_by_locationIdService {
    if(!this._custom_get_location_container_by_locationId) {
      this._custom_get_location_container_by_locationId = this.injector.get(app_custom_get_location_container_by_locationIdService);
    }
    return this._custom_get_location_container_by_locationId;
  }
  private _ds_find_lots: app_ds_find_lotsService;
  public get ds_find_lots(): app_ds_find_lotsService {
    if(!this._ds_find_lots) {
      this._ds_find_lots = this.injector.get(app_ds_find_lotsService);
    }
    return this._ds_find_lots;
  }
  private _ds_get_completed_putaway_task_by_licenseplateId_shipmentId: app_ds_get_completed_putaway_task_by_licenseplateId_shipmentIdService;
  public get ds_get_completed_putaway_task_by_licenseplateId_shipmentId(): app_ds_get_completed_putaway_task_by_licenseplateId_shipmentIdService {
    if(!this._ds_get_completed_putaway_task_by_licenseplateId_shipmentId) {
      this._ds_get_completed_putaway_task_by_licenseplateId_shipmentId = this.injector.get(app_ds_get_completed_putaway_task_by_licenseplateId_shipmentIdService);
    }
    return this._ds_get_completed_putaway_task_by_licenseplateId_shipmentId;
  }
  private _ncustom_ds_order_dock_appointment_complete: app_ncustom_ds_order_dock_appointment_completeService;
  public get ncustom_ds_order_dock_appointment_complete(): app_ncustom_ds_order_dock_appointment_completeService {
    if(!this._ncustom_ds_order_dock_appointment_complete) {
      this._ncustom_ds_order_dock_appointment_complete = this.injector.get(app_ncustom_ds_order_dock_appointment_completeService);
    }
    return this._ncustom_ds_order_dock_appointment_complete;
  }
}

