import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService, EModalSize, EToasterType, EToasterPosition } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 


import { LocusRobotics_jobs_detail_by_order_gridComponent } from './LocusRobotics.jobs_detail_by_order_grid.component';
import { LocusRobotics_jobs_header_gridComponent } from './LocusRobotics.jobs_header_grid.component';
import { LocusRobotics_open_jobs_widgetComponent } from './LocusRobotics.open_jobs_widget.component';
import { LocusRobotics_owners_dd_singleComponent } from './LocusRobotics.owners_dd_single.component'
import { LocusRobotics_projects_dd_multiComponent } from './LocusRobotics.projects_dd_multi.component'
import { LocusRobotics_warehouses_dd_singleComponent } from './LocusRobotics.warehouses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_jobs_detail_by_order_gridComponent),
    forwardRef(() => LocusRobotics_jobs_header_gridComponent),
    forwardRef(() => LocusRobotics_open_jobs_widgetComponent),
    forwardRef(() => LocusRobotics_owners_dd_singleComponent),
    forwardRef(() => LocusRobotics_projects_dd_multiComponent),
    forwardRef(() => LocusRobotics_warehouses_dd_singleComponent),
  ],
  selector: 'LocusRobotics-jobs_hub',
  templateUrl: './LocusRobotics.jobs_hub.component.html'
})
export class LocusRobotics_jobs_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    customer: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    cart_or_job_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      manage_menu: new ToolModel(new SplitButtonModel<{ cart_configuration: ButtonModel, settings: ButtonModel, printer_settings: ButtonModel, message_trace: ButtonModel, logs: ButtonModel }>(
        'manage_menu',
        new ButtonStyles(null, null),
        false,
        'Manage',
        'icon-ic_fluent_settings_20_regular',
        [
          new ButtonModel('cart_configuration', new ButtonStyles(null, null), false, 'Cart configuration', ''),
          new ButtonModel('settings', new ButtonStyles(null, null), false, 'Locus configuration', ''),
          new ButtonModel('printer_settings', new ButtonStyles(null, null), false, 'Printer configuration', ''),
          new ButtonModel('message_trace', new ButtonStyles(null, null), false, 'Message tracing', ''),
          new ButtonModel('logs', new ButtonStyles(null, null), false, 'Logs', '')
        ])
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      pallet_building: new ToolModel(new ButtonModel('pallet_building', new ButtonStyles(null, null), false, 'Pallet building', 'icon-ic_fluent_tetris_app_20_filled')
    ),
      pull_orders: new ToolModel(new ButtonModel('pull_orders', new ButtonStyles(null, null), false, 'Push orders to Locus', 'ms-Icon ms-Icon--CharticulatorOrderRow')
    )
  };

  actionbar = {
  };

 filters = {
    customer: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['customer'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Customer', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    cart_or_job_id: new FieldModel(new TextBoxModel(this.formGroup.controls['cart_or_job_id'] as DatexFormControl, null, false, 'Scan cart or job Id')
, new ControlContainerStyles(null, null), 'Cart / Job #', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true),
  newGroup2: new FieldsetModel('Label of newGroup2', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      jobs: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      jobs: new TabItemModel(
        this.rootTabGroup, 
        'Jobs', 
        true,
        {
          by_job: new TabItemModel(
          this.subTabGroups.jobs, 
          'by Job', 
          ),
          order: new TabItemModel(
          this.subTabGroups.jobs, 
          'by Order', 
          ),
        }
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_jobs_order_jobs_header_grid_inParams_projectIds: number[];
    get $tabs_jobs_order_jobs_header_grid_inParams_projectIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_jobs_order_jobs_header_grid_inParams_projectIds, expr)) {
        this.cacheValueFor_$tabs_jobs_order_jobs_header_grid_inParams_projectIds = expr;
      }
      return this.cacheValueFor_$tabs_jobs_order_jobs_header_grid_inParams_projectIds;
    }
  
    get $tabs_jobs_order_jobs_header_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
        @ViewChild('$tabs_jobs_by_job', { read: LocusRobotics_jobs_detail_by_order_gridComponent }) $tabs_jobs_by_job: LocusRobotics_jobs_detail_by_order_gridComponent;
        @ViewChild('$tabs_jobs_order', { read: LocusRobotics_jobs_header_gridComponent }) $tabs_jobs_order: LocusRobotics_jobs_header_gridComponent;
    //#endregion tabs children
    widgets = {
      open_orders_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    cacheValueFor_$widgets_open_orders_widget_inParams_projects: number[];
    get $widgets_open_orders_widget_inParams_projects(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_open_orders_widget_inParams_projects, expr)) {
        this.cacheValueFor_$widgets_open_orders_widget_inParams_projects = expr;
      }
      return this.cacheValueFor_$widgets_open_orders_widget_inParams_projects;
    }
  
    get $widgets_open_orders_widget_inParams_warehouse(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_open_orders_widget', { read:  LocusRobotics_open_jobs_widgetComponent }) $widgets_open_orders_widget: LocusRobotics_open_jobs_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_customer_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_customer_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.customer.control.value;
    
    return expr;
  }



  get $fields_warehouse_selector_inParams_warehouseId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.warehouse.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Locus Robotics`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Monitor and manage your Locus Robotics orders and bots`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: LocusRobotics_ShellService,
  private datasources: LocusRobotics_DatasourceService,
  private flows: LocusRobotics_FlowService,
  private reports: LocusRobotics_ReportService,
  private localization: LocusRobotics_LocalizationService,
  private operations: LocusRobotics_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.jobs,
    ]; 
    this.subTabGroups.jobs.tabs = [
        this.tabs.jobs.tabs.by_job,
        this.tabs.jobs.tabs.order,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Jobs hub';
    
    const $hub = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['customer']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_changed();
      });
    this.formGroup
      .controls['cart_or_job_id']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_cart_or_job_value_changed();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_open_orders_widget') {
      if (!isNil(this.$widgets_open_orders_widget) && !this.widgets.open_orders_widget.hidden) {
        this.$widgets_open_orders_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_jobs_by_job') {
        if (!isNil(this.$tabs_jobs_by_job) && !this.tabs.jobs.tabs.by_job.hidden) {
          this.$tabs_jobs_by_job.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_jobs_order') {
        if (!isNil(this.$tabs_jobs_order) && !this.tabs.jobs.tabs.order.hidden) {
          this.$tabs_jobs_order.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_pull_orders_clicked(event = null) {
    return this.on_pull_orders_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pull_orders_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  
  
  let date = (await $shell.LocusRobotics.openprompt_formDialog({getDate: true}, 'modal', EModalSize.Small)).date
  
  
  if ($utils.isDefined(date)) {
      let result = (await $flows.LocusRobotics.get_orders_to_queue({ minimumDateThreshold: date })).output
      await $hub.refresh();
  }
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  }
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.customer.control.value)) {
      const project = (await $datasources.LocusRobotics.ds_get_project_by_projectId.get({
          projectIds: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.customer.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_locus_settings_clicked(event = null) {
    return this.on_locus_settings_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_locus_settings_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  await $shell.Utilities.openconfigurations_gridDialog({application_name: 'Locus settings', column_names: {string_1: 'User', string_2: 'Password'}}, 'flyout', EModalSize.Xlarge)
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $hub.title = 'Locus Robotics'
  
  }
  on_cart_configurations_clicked(event = null) {
    return this.on_cart_configurations_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cart_configurations_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  await $shell.LocusRobotics.openbot_configuration_gridDialog('flyout', EModalSize.Xlarge);
  }
  on_logs_clicked(event = null) {
    return this.on_logs_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_logs_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openlogs_gridDialog({application_name: 'LOCUSROBOTICS', grid_title: 'Locus logs'}, 'flyout', EModalSize.Xlarge);
  }
  message_trace_clicked(event = null) {
    return this.message_trace_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async message_trace_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.Utilities.openmessages_gridDialog({application_name: 'LOCUSROBOTICS',grid_title: 'Locus message tracing', }, 'flyout', EModalSize.Xlarge)
  }
  on_build_pallet_clicked(event = null) {
    return this.on_build_pallet_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_build_pallet_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $shell.LocusRobotics.openpallet_build_hub({})
  }
  on_cart_or_job_value_changed(event = null) {
    return this.on_cart_or_job_value_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cart_or_job_value_changedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  let jobId = String($hub.filters.cart_or_job_id.control.value).trim()
  
  
  if($utils.isDefined(jobId)){
      jobId = (await $flows.LocusRobotics.get_orderJob_by_cart({jobId_or_cartId: jobId})).jobId
      if($utils.isDefined(jobId)){
          $shell.LocusRobotics.openpallet_build_hub({jobIds: [jobId]}, true)
      } else {
          $shell.LocusRobotics.openErrorDialog('Pallet build not available', `${String($hub.filters.cart_or_job_id.control.value).trim()} is not available for building`)
      }
  
  }
  
  }
  on_printers_clicked(event = null) {
    return this.on_printers_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_printers_clickedInternal(
    $hub: LocusRobotics_jobs_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  await $shell.Utilities.openconfigurations_gridDialog({application_name: 'Locus Printers', column_names: {string_1: 'Warehouse', string_2: 'Project', string_3: 'Retailer'}}, 'flyout', EModalSize.Xlarge)
  }
  //#endregion private flows
}
