import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService, EModalSize, EToasterType, EToasterPosition } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 



interface ILocusRobotics_jobs_detail_by_order_gridComponentEntity {
jobId?: string, jobPriority?: number, toteId?: string, jobDate?: string, status?: string, jobTaskCount?: number, bot?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, shippingContainer?: string, licensePlate?: string, botType?: string}

interface ILocusRobotics_jobs_detail_by_order_gridComponentInParams {
  orderId?: string}


class LocusRobotics_jobs_detail_by_order_gridComponentRowModel extends GridRowModel {
  grid: LocusRobotics_jobs_detail_by_order_gridComponent;
  entity: ILocusRobotics_jobs_detail_by_order_gridComponentEntity;


  options: { cancel_button: ButtonModel } = {
    cancel_button: new ButtonModel('cancel_button', new ButtonStyles(null, null), false, 'Cancel job', '')

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    toteId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    order: new GridCellModel(
      new CellStyles(['grid-table-cell-link','center'], null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    jobPriority: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    bot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    jobTaskCount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    last_known_location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    time_on_floor: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    jobDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'MM/DD/YYYY')
,
null
      ),
    jobId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: LocusRobotics_jobs_detail_by_order_gridComponent, entity: ILocusRobotics_jobs_detail_by_order_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.jobId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: LocusRobotics_jobs_detail_by_order_gridComponent, entity?: ILocusRobotics_jobs_detail_by_order_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.jobId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.jobId;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      orderId:  $grid.inParams.orderId ,
    };
    const data = await this.datasources.LocusRobotics.ds_jobs_header_by_orderId_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.toteId.displayControl as TextModel).text = $row.entity.toteId;
    (this.cells.order.displayControl as TextModel).text = $row.entity.orderId;
    (this.cells.status.displayControl as TextModel).text = $row.entity.status;
    (this.cells.jobPriority.displayControl as TextModel).text = $row.entity.jobPriority?.toString();
    (this.cells.bot.displayControl as TextModel).text = $row.entity?.bot;
    (this.cells.jobTaskCount.displayControl as TextModel).text = $row.entity.jobTaskCount?.toString();
    (this.cells.jobDate.displayControl as TextModel).text = $row.entity.jobDate;
    (this.cells.jobId.displayControl as TextModel).text = $row.entity.jobId;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'order' : {
        this.on_order_id_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_tote_id_clicked(event = null) {
    return this.on_tote_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_tote_id_clickedInternal(
    $row: LocusRobotics_jobs_detail_by_order_gridComponentRowModel,
  $grid: LocusRobotics_jobs_detail_by_order_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_detail_by_order_grid.on_tote_id_clicked');
  if($utils.isDefined($row.entity.toteId)){
      $shell.LocusRobotics.opensingle_tote_hubDialog({tote: $row.entity.toteId})
  
  }
  }
  on_bot_clicked(event = null) {
    return this.on_bot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_bot_clickedInternal(
    $row: LocusRobotics_jobs_detail_by_order_gridComponentRowModel,
  $grid: LocusRobotics_jobs_detail_by_order_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_detail_by_order_grid.on_bot_clicked');
  $shell.Utilities.openInfoDialog('Development', 'in progress...')
  }
  cancel_job_button(event = null) {
    return this.cancel_job_buttonInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async cancel_job_buttonInternal(
    $row: LocusRobotics_jobs_detail_by_order_gridComponentRowModel,
  $grid: LocusRobotics_jobs_detail_by_order_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  let message: string;
  
  
  const confirmation = await $shell.LocusRobotics.openConfirmationDialog(`Cancel job?`, `This will cancel job: ${$row.entity.jobId}. Once cancelled, the job will no longer be available for Locus.`, 'Confirm', 'Cancel')
  
  
  if (confirmation) {    
      let response = (await $flows.LocusRobotics.orderJob_cancel({
          jobId: $row.entity.jobId,
          transactionId: $utils.createGuid()
      }))
      if($utils.isDefined(response.reasons)){
          message = `Failed to cancel job ${$row.entity.jobId}`
      } else {
          message = `Successfully canceled job ${$row.entity.jobId}`
      }
  }
  
  await $shell.LocusRobotics.openInfoDialog(`Cancel job`, message)
  await $grid.refresh();
  }
  on_row_refresh_clicked(event = null) {
    return this.on_row_refresh_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_refresh_clickedInternal(
    $row: LocusRobotics_jobs_detail_by_order_gridComponentRowModel,
  $grid: LocusRobotics_jobs_detail_by_order_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_detail_by_order_grid.on_row_refresh_clicked');
  $row.refresh();
  }
  on_order_id_clicked(event = null) {
    return this.on_order_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_id_clickedInternal(
    $row: LocusRobotics_jobs_detail_by_order_gridComponentRowModel,
  $grid: LocusRobotics_jobs_detail_by_order_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  
  
  
  $shell.LocusRobotics.opensingle_job_hub({ orderId: Number($row.entity.orderId)})
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'LocusRobotics-jobs_detail_by_order_grid',
  templateUrl: './LocusRobotics.jobs_detail_by_order_grid.component.html'
})
export class LocusRobotics_jobs_detail_by_order_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: ILocusRobotics_jobs_detail_by_order_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: ILocusRobotics_jobs_detail_by_order_gridComponentInParams = { orderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     toteId: new GridHeaderModel(new HeaderStyles(null, null), 'Tote ID', false, false, null),       order: new GridHeaderModel(new HeaderStyles(['center'], null), 'Order', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       jobPriority: new GridHeaderModel(new HeaderStyles(null, null), 'Priority', false, false, null),       bot: new GridHeaderModel(new HeaderStyles(null, null), 'Bot', false, false, null),       jobTaskCount: new GridHeaderModel(new HeaderStyles(null, null), 'Total items', false, false, null),       last_known_location: new GridHeaderModel(new HeaderStyles(null, null), 'Last known location', false, false, null),       time_on_floor: new GridHeaderModel(new HeaderStyles(null, null), 'Time on floor', false, false, null),       jobDate: new GridHeaderModel(new HeaderStyles(null, null), 'Job date', false, false, null),       jobId: new GridHeaderModel(new HeaderStyles(null, null), 'Job', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: LocusRobotics_jobs_detail_by_order_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      build_pallet: new ToolModel(new ButtonModel('build_pallet', new ButtonStyles(null, null), true, 'Build pallet', 'icon-ic_fluent_tetris_app_20_filled')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: LocusRobotics_ShellService,
    private datasources: LocusRobotics_DatasourceService,
    private flows: LocusRobotics_FlowService,
    private reports: LocusRobotics_ReportService,
    private localization: LocusRobotics_LocalizationService,
    private operations: LocusRobotics_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Job details by order grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      orderId:  $grid.inParams.orderId ,
    };
    try {
    const data = await this.datasources.LocusRobotics.ds_jobs_header_by_orderId_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new LocusRobotics_jobs_detail_by_order_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: LocusRobotics_jobs_detail_by_order_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  
  for(let row of $grid.rows){
      let jobDate = new Date(row.entity.jobDate)
      let currentDate = new Date()
  
      let timeDifference: string = formatTimeDifference(jobDate, currentDate);
      row.cells.time_on_floor.displayControl.text = timeDifference;
      if(timeDifference.includes('day')){
          row.cells.time_on_floor.styles.setImportantClass();
      } else if (timeDifference.includes('hour')){
          row.cells.time_on_floor.styles.setAttentionClass();
      } else{
          row.cells.time_on_floor.styles.setCreationClass();
      }
      row.options.cancel_button.readOnly = true;
  }
  
  
  
  function formatTimeDifference(date1: Date, date2: Date): string {
      const differenceInMilliseconds: number = Math.abs(date2.getTime() - date1.getTime());
  
      const seconds = Math.floor(differenceInMilliseconds / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
  
      if (days > 0) {
          return `${days} day${days > 1 ? 's' : ''}`;
      } else if (hours > 0) {
          return `${hours} hour${hours > 1 ? 's' : ''}`;
      } else if (minutes > 0) {
          return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      } else {
          return `${seconds} second${seconds > 1 ? 's' : ''}`;
      }
  }
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: LocusRobotics_jobs_detail_by_order_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount === 1) {
      let isValidJob = (await $flows.LocusRobotics.get_orderJob_by_cart({ jobId_or_cartId: $grid.selectedRows[0].entity.jobId })).jobId
      if ($utils.isDefined(isValidJob)) {
          $grid.topToolbar.build_pallet.control.readOnly = false;
      } else {
          $grid.topToolbar.build_pallet.control.readOnly = true;
      }
  }
  
  
  
  
  
  
  
  
  
  }
  on_pallet_build_clicked(event = null) {
    return this.on_pallet_build_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_build_clickedInternal(
    $grid: LocusRobotics_jobs_detail_by_order_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $shell.LocusRobotics.openpallet_build_hubDialog({jobIds: [$grid.selectedRows[0].entity.jobId]}, "flyout", EModalSize.Xlarge)
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: LocusRobotics_jobs_detail_by_order_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.build_pallet.control.readOnly = true;
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
