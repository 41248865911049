import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


import { FootPrintManager_asn_order_licenseplates_gridComponent } from './FootPrintManager.asn_order_licenseplates_grid.component';
import { FootPrintManager_asn_order_receiving_tasks_gridComponent } from './FootPrintManager.asn_order_receiving_tasks_grid.component';
import { FootPrintManager_asn_orderlines_gridComponent } from './FootPrintManager.asn_orderlines_grid.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_asn_order_tasks_gridComponent } from './FootPrintManager.asn_order_tasks_grid.component';
import { AsnOrders_orderline_total_amount_widgetComponent } from './AsnOrders.orderline_total_amount_widget.component';
import { AsnOrders_orderline_total_received_widgetComponent } from './AsnOrders.orderline_total_received_widget.component';
import { AsnOrders_orderline_total_gross_received_widgetComponent } from './AsnOrders.orderline_total_gross_received_widget.component';
import { AsnOrders_orderclasses_dd_singleComponent } from './AsnOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_asn_order_licenseplates_gridComponent),
    forwardRef(() => FootPrintManager_asn_order_receiving_tasks_gridComponent),
    forwardRef(() => FootPrintManager_asn_orderlines_gridComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => FootPrintManager_asn_order_tasks_gridComponent),
    forwardRef(() => AsnOrders_orderline_total_amount_widgetComponent),
    forwardRef(() => AsnOrders_orderline_total_received_widgetComponent),
    forwardRef(() => AsnOrders_orderline_total_gross_received_widgetComponent),
    forwardRef(() => AsnOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
  ],
  selector: 'app-custom_asn_order_editor',
  templateUrl: './app.custom_asn_order_editor.component.html'
})
export class app_custom_asn_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { order_id: number } = { order_id: null };
  //#region Inputs
  @Input('order_id') set $inParams_order_id(v: number) {
    this.inParams.order_id = v;
  }
  get $inParams_order_id(): number {
    return this.inParams.order_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentIds?: number[], billingAddressId?: number, hasCompletedReceivingTasks?: boolean, hasPlannedReceivingTasks?: boolean, warehouseTransferId?: number, suppressPrompts?: boolean, suppressFlows?: boolean } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, AWBNumber?: string, BillingAddresId?: number, DespatchAdvice?: string, EntryNumber?: string, FDAHold?: boolean, HotReceipt?: boolean, InboundOwnerSgln?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, Priority?: number } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    hot_receipt: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    reference: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    fda_hold: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    awb_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entry_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process')
    ),
      validate: new ToolModel(new ButtonModel('validate', new ButtonStyles(null, null), false, 'Validate', 'ms-Icon ms-Icon--ActivateOrders')
    ),
      auto_receive: new ToolModel(new ButtonModel('auto_receive', new ButtonStyles(null, null), false, 'Auto Receive', 'ms-Icon ms-Icon--AutomateFlow')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'ms-Icon ms-Icon--AcceptMedium')
    ),
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, 'Unreceive All', 'ms-Icon ms-Icon--Annotation')
    ),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, 'Revert', 'ms-Icon ms-Icon--ClearSelection')
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'ms-Icon ms-Icon--Calendar')
    ),
      update_project: new ToolModel(new ButtonModel('update_project', new ButtonStyles(['destructive'], null), false, 'Update Project', 'ms-Icon ms-Icon--ProcessingRun')
    ),
      auto_transfer: new ToolModel(new ButtonModel('auto_transfer', new ButtonStyles(null, null), false, 'Auto transfer', 'ms-Icon ms-Icon--DependencyAdd')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, 'Cancel', 'ms-Icon ms-Icon--Blocked')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    ),
      separator4: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Print')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Attach')
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Questionnaire')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Lookupcode', true)
,
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order Class', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    order_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order Status', false)
,
    hot_receipt: new FieldModel(new CheckBoxModel(this.formGroup.controls['hot_receipt'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(['important'], null), 'Hot Receipt', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'PO', true)
,
    ship_from_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Ship From', 'ms-Icon ms-Icon--AccountBrowser')
, new ControlContainerStyles(null, null), '', false)
,
    ship_from_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Clear', 'ms-Icon ms-Icon--Blocked')
, new ControlContainerStyles(null, null), '', false)
,
    ship_from: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference', false)
,
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Expected date', false)
,
    priority: new FieldModel(new TextBoxModel(this.formGroup.controls['priority'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Priority', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    custom_fields: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Custom fields', 'ms-Icon ms-Icon--Edit')
, new ControlContainerStyles(null, null), '', false)
,
    fda_hold: new FieldModel(new CheckBoxModel(this.formGroup.controls['fda_hold'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'FDA Hold', false)
,
    awb_number: new FieldModel(new TextBoxModel(this.formGroup.controls['awb_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'AWB', false)
,
    entry_number: new FieldModel(new TextBoxModel(this.formGroup.controls['entry_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Entry Number', false)
,
    fda_order: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'FDA Order', 'ms-Icon ms-Icon--PaddingRight')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
  newGroup3: new FieldsetModel('FDA Hold', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      licenseplates: new TabGroupModel(),
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      licenseplates: new TabItemModel(
        this.rootTabGroup, 
        'License Plates', 
        true,
        {
          grouped: new TabItemModel(
          this.subTabGroups.licenseplates, 
          'Grouped', 
          ),
          ungrouped: new TabItemModel(
          this.subTabGroups.licenseplates, 
          'Ungrouped', 
          ),
        }
        ),
      orderlines: new TabItemModel(
        this.rootTabGroup, 
        'Order Lines', 
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          ),
        }
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_order_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_order_status_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_licenseplates_ungrouped_asn_order_receiving_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_licenseplates_ungrouped_asn_order_receiving_tasks_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_orderlines_asn_orderlines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_showAllOpCodes(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.Id];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    get $tabs_tasks_asn_order_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_tasks_asn_order_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
        @ViewChild('$tabs_licenseplates_grouped', { read: FootPrintManager_asn_order_licenseplates_gridComponent }) $tabs_licenseplates_grouped: FootPrintManager_asn_order_licenseplates_gridComponent;
        @ViewChild('$tabs_licenseplates_ungrouped', { read: FootPrintManager_asn_order_receiving_tasks_gridComponent }) $tabs_licenseplates_ungrouped: FootPrintManager_asn_order_receiving_tasks_gridComponent;
      @ViewChild('$tabs_orderlines', { read: FootPrintManager_asn_orderlines_gridComponent }) $tabs_orderlines: FootPrintManager_asn_orderlines_gridComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_asn_order_tasks_gridComponent }) $tabs_tasks: FootPrintManager_asn_order_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      orderline_total_amount_widget: new WidgetModel(),
      orderline_total_received_widget: new WidgetModel(),
      orderline_total_gross_received_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_orderline_total_amount_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $widgets_orderline_total_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $widgets_orderline_total_gross_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_orderline_total_amount_widget', { read:  AsnOrders_orderline_total_amount_widgetComponent }) $widgets_orderline_total_amount_widget: AsnOrders_orderline_total_amount_widgetComponent;
      @ViewChild('$widgets_orderline_total_received_widget', { read:  AsnOrders_orderline_total_received_widgetComponent }) $widgets_orderline_total_received_widget: AsnOrders_orderline_total_received_widgetComponent;
      @ViewChild('$widgets_orderline_total_gross_received_widget', { read:  AsnOrders_orderline_total_gross_received_widgetComponent }) $widgets_orderline_total_gross_received_widget: AsnOrders_orderline_total_gross_received_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    hot_receipt: this.fields.hot_receipt.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    priority: this.fields.priority.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    fda_hold: this.fields.fda_hold.control.valueChanges
    ,
    awb_number: this.fields.awb_number.control.valueChanges
    ,
    entry_number: this.fields.entry_number.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.licenseplates,
      this.tabs.orderlines,
      this.tabs.billing,
      this.tabs.tasks,
    ]; 
    this.subTabGroups.licenseplates.tabs = [
        this.tabs.licenseplates.tabs.grouped,
        this.tabs.licenseplates.tabs.ungrouped,
    ];    
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit ASN Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.order_id 
    };

    const data = await this.datasources.app.custom_ds_asn_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.PreferredWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.hot_receipt.control as CheckBoxModel).reset($editor.entity.HotReceipt);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.priority.control as TextBoxModel).reset(Math.min(...$editor.entity.ShipmentOrderLookups?.map(sol => sol?.Shipment?.Priority)).toString());
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.fda_hold.control as CheckBoxModel).reset($editor.entity.FDAHold);
    (this.fields.awb_number.control as TextBoxModel).reset($editor.entity.AWBNumber);
    (this.fields.entry_number.control as TextBoxModel).reset($editor.entity.EntryNumber);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_orderline_total_amount_widget') {
      if (!isNil(this.$widgets_orderline_total_amount_widget) && !this.widgets.orderline_total_amount_widget.hidden) {
        this.$widgets_orderline_total_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_received_widget') {
      if (!isNil(this.$widgets_orderline_total_received_widget) && !this.widgets.orderline_total_received_widget.hidden) {
        this.$widgets_orderline_total_received_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_gross_received_widget') {
      if (!isNil(this.$widgets_orderline_total_gross_received_widget) && !this.widgets.orderline_total_gross_received_widget.hidden) {
        this.$widgets_orderline_total_gross_received_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_licenseplates_grouped') {
        if (!isNil(this.$tabs_licenseplates_grouped) && !this.tabs.licenseplates.tabs.grouped.hidden) {
          this.$tabs_licenseplates_grouped.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_licenseplates_ungrouped') {
        if (!isNil(this.$tabs_licenseplates_ungrouped) && !this.tabs.licenseplates.tabs.ungrouped.hidden) {
          this.$tabs_licenseplates_ungrouped.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_orderlines') {
        if (!isNil(this.$tabs_orderlines) && !this.tabs.orderlines.hidden) {
          this.$tabs_orderlines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tasks') {
        if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
          this.$tabs_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .hot_receipt
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .fda_hold
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_fda_hold_process();
      });
    this.$formGroupFieldValidationObservables
      .awb_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .entry_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_delete_clicked');
  const result = await $flows.AsnOrders.is_asn_order_deletable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
      }
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.openErrorDialog('Order cannot be deleted.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
      return;
  } else {
      const confirm = await $shell.openConfirmationDialog('Delete Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.AsnOrders.delete_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.openErrorDialog('Order cannot be deleted.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
                  return;
              }
              else {
                  $editor.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.openErrorDialog('Order delete error', 'An error occurred during the deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_cancel_clicked');
  const result = await $flows.AsnOrders.is_asn_order_cancelable_flow({
      orderId: $editor.entity.Id, 
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
      }
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.openErrorDialog('Order cannot be cancelled.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
      return;
  } else {
      const confirm = await $shell.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const result = await $flows.AsnOrders.cancel_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.openErrorDialog('Order cannot be cancelled.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
                  return;
              }
              else {
  
                  const title = 'Cancel Order';
                  const message = `Order ${$editor.entity.LookupCode} cancelled.`;
                  await $shell.AsnOrders.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_complete_clicked');
  const result = await $flows.AsnOrders.is_asn_order_completable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
      }
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
      return;
  } else {
  
      try {
  
          var confirmation = false;
          // Lock down complete button to handle potential double clicks
          $editor.toolbar.complete.control.readOnly = true;
  
          $editor.toolbar.auto_receive.control.readOnly = true;
          $editor.toolbar.unreceive.control.readOnly = true;
  
          // Change icon to a rolling progress
          $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  
  
          // Check if the order contains pending receiving tasks
          const plannedTasks = (await $datasources.AsnOrders.ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1.get({
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
          })).result;
  
          if ($utils.isDefined(plannedTasks)) {
  
              //  Prompt for asn order transfer outstanding options
              var userConfirmed = false;
              var ignoreConfirmed = false;
              var cancelCompletion = false;
  
              const dialogResult = await $shell.FootPrintManager.openasn_order_transfer_outstanding_options_formDialog('modal');
              userConfirmed = dialogResult.confirm;
              ignoreConfirmed = dialogResult.ignore;
              cancelCompletion = dialogResult.cancelCompletion;
              const notes = dialogResult.notes;
              const createNewOrder = dialogResult.createNewOrder;
              const copyUdfs = dialogResult.copyUdfs;
  
              if (userConfirmed) {
                  var openNewOrder = false;
  
  
                  $editor.toolbar.complete.control.label = 'Transferring Outstanding';
  
                  const result = await $flows.AsnOrders.transfer_outstanding_asn_order_flow({
                      orderId: $editor.entity.Id,
                      shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId,
                      notes: notes,
                      createNewOrder: createNewOrder,
                      copyUdfs: copyUdfs
                  });
                  const reasons = result.reasons;
  
                  var newOrderId = result.newOrderId;
                  if ($utils.isDefined(newOrderId)) {
                      openNewOrder = true;
                      confirmation = true;
                  }
  
                  if ($utils.isDefined(reasons)) {
                      await $shell.FootPrintManager.openErrorDialog('Transfer outstanding cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
  
                      // Reset buttons
                      $editor.toolbar.complete.control.styles.resetStyle();
                      $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                      $editor.toolbar.complete.control.label = 'Complete';
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.auto_receive.control.readOnly = false;
                      $editor.toolbar.unreceive.control.readOnly = false;
  
                      confirmation = false;
  
                      return;
                  }
              }
  
              if (!userConfirmed) {
                  if (cancelCompletion) {
  
                      // Reset buttons
                      $editor.toolbar.complete.control.styles.resetStyle();
                      $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                      $editor.toolbar.complete.control.label = 'Complete';
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.auto_receive.control.readOnly = false;
                      $editor.toolbar.unreceive.control.readOnly = false;
  
                      confirmation = false;
  
                      return;
  
                  }
                  else if (ignoreConfirmed) {
                      // Exit and move on below to complete the order
                      confirmation = true;
                  }
                  else {
                      // All options are false
  
                      // Reset buttons
                      $editor.toolbar.complete.control.styles.resetStyle();
                      $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                      $editor.toolbar.complete.control.label = 'Complete';
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.auto_receive.control.readOnly = false;
                      $editor.toolbar.unreceive.control.readOnly = false;
  
                      confirmation = false;
  
                      return;
                  }
              }
  
  
          }
          else {
              const confirmComplete = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
              if (confirmComplete) {
                  confirmation = true;
              }
              else {
  
                  // Reset buttons
                  $editor.toolbar.complete.control.styles.resetStyle();
                  $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                  $editor.toolbar.complete.control.label = 'Complete';
                  $editor.toolbar.complete.control.readOnly = false;
  
                  $editor.toolbar.auto_receive.control.readOnly = false;
                  $editor.toolbar.unreceive.control.readOnly = false;
                  
                  return;
              }
          }
  
          if (confirmation) {
              $editor.toolbar.complete.control.label = 'Completing';
  
              const result = await $flows.AsnOrders.complete_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
  
                  // Reset buttons
                  $editor.toolbar.complete.control.styles.resetStyle();
                  $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                  $editor.toolbar.complete.control.label = 'Complete';
                  $editor.toolbar.complete.control.readOnly = false;
  
                  $editor.toolbar.auto_receive.control.readOnly = false;
                  $editor.toolbar.unreceive.control.readOnly = false;
  
                  return;
              }
              else {
  
                  // Hide buttons on completion
                  $editor.toolbar.complete.hidden = true;
                  $editor.toolbar.auto_receive.hidden = true;
                  $editor.toolbar.unreceive.hidden = true;
  
                  const title = 'Complete Order';
                  const message = `Order ${$editor.entity.LookupCode} completed.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
  
                  // If the open new order boolean is true and we succesfully created a new orderId
                  if (openNewOrder && $utils.isDefined(newOrderId)) {
                      $shell.FootPrintManager.openasn_order_editor({ order_id: newOrderId })
                  }
  
              }
          }
  
  
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  
  }
  
  await $editor.refresh();
  
  if ($editor.entity.OrderStatusId === 4) {
      let warehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $editor.entity.PreferredWarehouseId })).result;
      if ($utils.isDefined(warehouse) && warehouse.Name.trim().toUpperCase() === 'MEMI') {
          let warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_lookupcode.get({ lookupcode: `ASN-${$editor.entity.LookupCode}` })).result[0];
  
          if ($utils.isDefined(warehouseTransfer)) {
              $editor.vars.warehouseTransferId = warehouseTransfer.Id;
  
              // Not completed or cancelled
              if (warehouseTransfer.StatusId !== 3 && warehouseTransfer.StatusId !== 4) {
                  $editor.on_auto_transfer_clicked();
              }
          }
          else {
              $editor.on_auto_transfer_clicked();
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_data_loaded');
  $editor.vars.suppressFlows = true;
  
  // Set hasCompletedReceivingTasks
  let tasks = (await $datasources.AsnOrders.ds_find_tasks.get({ orderId: $editor.entity.Id, operationCodeId: 2 })).result;
  $editor.vars.hasCompletedReceivingTasks = $utils.isDefined(tasks.find(t => t.StatusId === 2));
  $editor.vars.hasPlannedReceivingTasks = $utils.isDefined(tasks.find(t => t.StatusId === 4));
  
  $editor.fields.fda_hold.control.readOnly = true;
  let setFdaHoldReadOnly = false;
  // Shipment created if not found in the on_init flow
  
  
  // Set editor title
  $editor.title = `ASN Order ${$editor.entity.LookupCode}`;
  
  var shipFrom = '';
  
  const orderAddress = (await $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.entity.Id }));
  if ($utils.isDefined(orderAddress)) {
      shipFrom = orderAddress.address;
  
  }
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipFrom)) {
      if (!shipFrom.startsWith('ord-')) {
  
          $editor.fields.ship_from.hidden = false;
          $editor.fields.ship_from.control.text = shipFrom;
          $editor.fields.ship_from_clear.hidden = false;
  
      } else {
          $editor.fields.ship_from.hidden = true;
          $editor.fields.ship_from.control.text = '';
          $editor.fields.ship_from_clear.hidden = true;
      }
  }
  else {
      $editor.fields.ship_from.hidden = true;
      $editor.fields.ship_from.control.text = '';
      $editor.fields.ship_from_clear.hidden = true;
  }
  
  // Auto transfer
  $editor.toolbar.auto_transfer.hidden = true;
  if ($editor.entity.OrderStatusId === 4) {
      let warehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $editor.entity.PreferredWarehouseId })).result;
      if ($utils.isDefined(warehouse) && warehouse.Name.trim().toUpperCase() === 'MEMI') {
          let warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_lookupcode.get({ lookupcode: `ASN-${$editor.entity.LookupCode}` })).result[0];
  
          if ($utils.isDefined(warehouseTransfer)) {
              $editor.vars.warehouseTransferId = warehouseTransfer.Id;
  
              // Not completed or cancelled
              if (warehouseTransfer.StatusId !== 3 && warehouseTransfer.StatusId !== 4) {
                  $editor.toolbar.auto_transfer.hidden = false;
              }
          }
          else {
              $editor.toolbar.auto_transfer.hidden = false;
          }
      }
  }
  
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.entity.Id
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  }
  else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  }
  
  
  // Fetch shipments
  const shipments = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.PurchaseOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.vars.shipmentIds.push(shipments[0].ShipmentId);
          $editor.fields.warehouse.required = true;
      }
  
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      // Enable/Disable Components
      // Process
      let reasons = (await $flows.AsnOrders.is_asn_order_processable_flow({ orderId: $editor.entity.Id })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.process);
      }
      else {
          enableComponent($editor.toolbar.process);
      }
  
      reasons = (await $flows.AsnOrders.is_asn_order_auto_receivable_flow({ orderId: $editor.entity.Id })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.auto_receive);
      }
      else {
          enableComponent($editor.toolbar.auto_receive);
      }
  
      // Complete
      reasons = (await $flows.AsnOrders.is_asn_order_completable_flow({
          orderId: $editor.entity.Id,
          optimalInputs: {
              statusId: $editor.entity.OrderStatusId,
              hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
          }
      })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.complete);
      }
      else {
          enableComponent($editor.toolbar.complete);
      }
  
      // Cancel
      reasons = (await $flows.AsnOrders.is_asn_order_cancelable_flow({
          orderId: $editor.entity.Id,
          optimalInputs: {
              statusId: $editor.entity.OrderStatusId,
              hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
          }
      })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.cancel);
      }
      else {
          enableComponent($editor.toolbar.cancel);
      }
  
      // Revert
      reasons = (await $flows.AsnOrders.is_asn_order_revertable_flow({
          orderId: $editor.entity.Id,
          useValidation: false,
          optimalInputs: {
              statusId: $editor.entity.OrderStatusId,
              hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
          }
      })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.revert);
      }
      else {
          enableComponent($editor.toolbar.revert);
      }
  
      // Unreceive All
      reasons = (await $flows.AsnOrders.is_asn_order_unreceivable_flow({
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
      })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.unreceive);
      }
      else {
          enableComponent($editor.toolbar.unreceive);
      }
  
      // Update Project
      if ($utils.isDefined((await $flows.custom_is_asn_order_updatable_project_flow({
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
      })).reason)) {
          disableComponent($editor.toolbar.update_project);
      }
      else {
          enableComponent($editor.toolbar.update_project);
      }
  
      // Delete
      reasons = (await $flows.AsnOrders.is_asn_order_deletable_flow({
          orderId: $editor.entity.Id,
          optimalInputs: {
              statusId: $editor.entity.OrderStatusId,
              hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
          }
      })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.on_delete);
      }
      else {
          enableComponent($editor.toolbar.on_delete);
      }
  
      // Validate
      reasons = (await $flows.AsnOrders.is_asn_order_validatable_flow({
          orderId: $editor.entity.Id,
          optimalInputs: {
              statusId: $editor.entity.OrderStatusId,
              hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
          }
      })).reasons;
      if ($utils.isDefined(reasons)) {
          disableComponent($editor.toolbar.validate);
      }
      else {
          enableComponent($editor.toolbar.validate);
      }
  
  
      // Check status
      if ($editor.entity.OrderStatusId === 1) { // Created
          // Enable components
  
  
          $editor.fields.lookupcode.control.readOnly = true;
          enableComponent($editor.fields.order_class);
          enableComponent($editor.fields.vendor_reference);
          enableComponent($editor.fields.expected_date);
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
      }
      else if ($editor.entity.OrderStatusId === 2048) { // ApprovalRequired
          // Enable components
  
  
          $editor.fields.lookupcode.control.readOnly = true;
          enableComponent($editor.fields.order_class);
          enableComponent($editor.fields.vendor_reference);
          enableComponent($editor.fields.expected_date);
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
      }
      else if ($editor.entity.OrderStatusId === 4096) { // Rejected
          // Enable components
  
  
          $editor.fields.lookupcode.control.readOnly = true;
          enableComponent($editor.fields.order_class);
          enableComponent($editor.fields.vendor_reference);
          enableComponent($editor.fields.expected_date);
  
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
      }
      else if ($editor.entity.OrderStatusId === 2) {
          // processing status
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
  
          // Set custom status
          let order_state = await $flows.AsnOrders.get_asn_order_state({
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
          });
  
          if ($utils.isDefined(order_state.state)) {
              switch (order_state.state) {
                  case 2: {
                      $editor.fields.order_status.control.text = 'Ready to receive'
                      break;
                  }
                  case 3: {
                      $editor.fields.order_status.control.text = 'Receiving in progress'
                      break;
                  }
                  case 4: {
                      $editor.fields.order_status.control.text = 'Ready to complete'
                      break;
                  }
  
              }
  
          }
  
      }
      else {
  
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
      }
  }
  
  
  
  // FDA Hold Toggle and Label Logic
  
  // If the order is not in a created status lock down the fda hold toggle
  if ($editor.entity.OrderStatusId != 1) {
      setFdaHoldReadOnly = true;
  }
  
  // Enable fda hold  toggle if FDAHold value is set to true
  if ($utils.isDefined($editor.entity.FDAHold) && $editor.entity.FDAHold) {
      setFdaHoldReadOnly = false;
  }
  
  const awb = $editor.fields.awb_number.control.value;
  const entryNumber = $editor.fields.entry_number.control.value;
  const projectId = $editor.entity.ProjectId;
  const lookupcode = $editor.entity.LookupCode;
  
  if ($utils.isAllDefined(awb, entryNumber, projectId)) {
      const order = (await $datasources.custom_ds_get_fda_order_by_awb_entrynumber.get({
          awb: awb,
          entrynumber: entryNumber,
          projectId: projectId,
          lookupcode: lookupcode
      })).result;
  
      if ($utils.isDefined(order)) {
  
          const orderId = order.Id;
          const shipmentId = order.ShipmentOrderLookups[0]?.ShipmentId;
  
          // Disable fda hold process toggle if order already exists and has been disabled
          if ($utils.isDefined($editor.entity.FDAHold) && !$editor.entity.FDAHold) {
              setFdaHoldReadOnly = true;
          }
  
          // Show the status of the FDA Hold Order on the label
          if (order.OrderStatusId === 1) {
              $editor.fields.fda_order.label = 'Order created'
          }
  
          else if (order.OrderStatusId === 4) {
              $editor.fields.fda_order.label = 'Order completed'
          }
          else if (order.OrderStatusId === 8) {
              $editor.fields.fda_order.label = 'Order cancelled';
          }
  
          else {
  
              let order_state = await $flows.PurchaseOrders.get_purchase_order_state({
                  orderId: orderId,
                  shipmentId: shipmentId
              });
  
              if ($utils.isDefined(order_state.state)) {
                  switch (order_state.state) {
                      case 2: {
                          $editor.fields.fda_order.label = 'Ready to receive'
                          break;
                      }
                      case 3: {
                          $editor.fields.fda_order.label = 'Receiving in progress'
                          break;
                      }
                      case 4: {
                          $editor.fields.fda_order.label = 'Ready to complete'
                          break;
                      }
  
                  }
  
              }
          }
  
      }
      else {
          $editor.fields.fda_order.label = ''
      }
  
  } else {
      $editor.fields.fda_order.label = ''
  }
  
  $editor.fields.fda_hold.control.readOnly = setFdaHoldReadOnly;
  
  $editor.vars.suppressFlows = false;
  
  /******************************************
   * FUNCTIONS
  *******************************************/
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_init');
  // Disable entire form
  $editor.vars.suppressFlows = false;
  
  $editor.vars.suppressPrompts = false;
  
  // Hidden fields
  $editor.fields.expected_date.hidden = true;
  
  
  $editor.vars.shipmentIds = [];
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.DateFormat}, ${$settings.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  // Create shipment if none found
  
  const shipments = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.order_id })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.order_id })).result;
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.order_id,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
  
      }
  
  
  
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.order_id })).result;
  if (!$utils.isDefined(order.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.order_id, entity: payload });
  
  }
  
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_owner_changed');
  $editor.fields.project.control.value = null;
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_save');
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.vars.shipmentIds[0];
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  if ($editor.fields.hot_receipt.control.isChanged) {
      payload.HotReceipt = $editor.fields.hot_receipt.control.value;
  }
  
  if ($editor.fields.awb_number.control.isChanged) {
      payload.AWBNumber = $editor.fields.awb_number.control.value;
  }
  
  if ($editor.fields.entry_number.control.isChanged)
  {
      payload.EntryNumber = $editor.fields.entry_number.control.value;
  }
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.entity.Id,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": $utils.isDefined($editor.fields.warehouse.control.value) ? $editor.fields.warehouse.control.value : order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1,
                  "Priority": $editor.fields.priority.control.value ?? null,
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
          $editor.vars.shipmentIds.push(nextShipmentId);
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
     if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.priority.control.isChanged) {
          payload.Priority = $editor.fields.priority.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  }
  
  
  
  await $editor.refresh();
  }
  on_ship_from_clicked(event = null) {
    return this.on_ship_from_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_ship_from_clicked');
  
  const dialogResult = await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [1, 3, 4, 5], // Billing, Operations, Residential, Commerical
      accountTypeId: 1, // Vendor
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm;
  const accountId = dialogResult.accountId;
  const addressId = dialogResult.addressId;
  
  if (userConfirmed && $utils.isAllDefined(accountId, addressId)) {
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  }
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_revert_clicked');
  const result = await $flows.AsnOrders.is_asn_order_revertable_flow({
      orderId: $editor.entity.Id,
       useValidation: false,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
      }
  });
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.openErrorDialog('Order cannot be reverted.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Revert Order', `Are you sure you want to bring order ${$editor.entity.LookupCode} back to its original created status?`, 'Yes', 'No');
      if (confirm) {
          try {
              // Lock down buttons
              $editor.toolbar.revert.control.readOnly = true;
  
              const result = await $flows.AsnOrders.revert_asn_order_flow({
                  orderId: $editor.entity.Id,
                      useValidation: false
              });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.openErrorDialog('Order cannot be reverted.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
                  $editor.toolbar.revert.control.readOnly = false;
                  return;
              }
              else {
                  $editor.refresh();
                  $editor.toolbar.revert.hidden = true;
                  $editor.toolbar.revert.control.readOnly = true;
                  $editor.toolbar.validate.hidden = false;
                  $editor.toolbar.validate.control.readOnly = false;
              }
          } catch (error) {
              $editor.toolbar.revert.control.readOnly = false;
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.openErrorDialog('Order revert error', 'An error occurred while reverting the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_auto_receive_clicked(event = null) {
    return this.on_auto_receive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_receive_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_auto_receive_clicked');
  // Validate order can be auto received
  let reasons = (await $flows.AsnOrders.is_asn_order_auto_receivable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.Id,
          hasPlannedReceivingTasks: $editor.vars.hasPlannedReceivingTasks
      }
  })).reasons;
  
  
  if ($utils.isDefined(reasons)) {
      $shell.openErrorDialog('Order cannot be Auto Received', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
      return;
  }
  
  // Auto Receive
  let result = (await $flows.AsnOrders.auto_receive_asn_orders({ orderIds: [$editor.entity.Id] }));
  
  // Check/Display response
  let message = '';
  let header = '';
  
  if (result.confirmedLicensePlates > 0 && result.confirmedPackagedAmount > 0) {
      header = 'Auto Receive Complete';
      message += `Successfully received ${result.confirmedLicensePlates} license plates along with ${result.confirmedPackagedAmount} packaged units of inventory. `;
  }
  if ($utils.isDefined(result.reasons)) {
      if (header == '') { header = 'Auto Receive Failed'; }
      else { header += ' with exceptions'; }
  
      message += `${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue}`;
  }
  
  await $shell.openInfoDialog(header, message);
  
  $editor.refresh();
  }
  on_validate_clicked(event = null) {
    return this.on_validate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_validate_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_validate_clicked');
  $editor.toolbar.validate.control.readOnly = true;
  
  // Check required fields
  if (!$utils.isDefined($editor.fields.vendor_reference.control.value)) {
      $shell.openErrorDialog('Order cannot be Validated', 'Missing value for vendor reference field.');
      return;
  }
  
  // Validate order can be validated
  let reasons = (await $flows.AsnOrders.is_asn_order_validatable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.hasCompletedReceivingTasks
      }
  })).reasons;
  
  
  if ($utils.isDefined(reasons)) {
      $shell.openErrorDialog('Order cannot be Validated', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue);
      return;
  }
  
  // Validate
  let result = (await $flows.AsnOrders.validate_order_flow({ orderId: $editor.entity.Id })).reason;
  await $editor.refresh();
  
  
  if ($utils.isDefined(result)) {
      $shell.openErrorDialog('Validation Error', result);
      $editor.toolbar.validate.control.readOnly = false;
  }
  else {
      $editor.toolbar.revert.hidden = false;
      $editor.toolbar.revert.control.readOnly = false;
      $editor.toolbar.validate.hidden = true;
      $editor.toolbar.validate.control.readOnly = true;
  }
  
  await $editor.on_data_loaded();
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_appointment_clicked');
  (await $flows.Utilities.get_username_flow({})).userName
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.entity.Id
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      })
  
  } else {
        // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.entity.Id
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_print_clicked');
  
  
  
  await $shell.AsnOrders.openreceiving_reportDialog({ orderId: $editor.entity.Id });
  
  
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_unreceive_clicked');
  const result = await $flows.AsnOrders.is_asn_order_unreceivable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be unreceived.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openunreceiving_all_options_formDialog({
          title: 'Unreceive and bring back entire order to a planned state?'
      });
      var userConfirmed = dialogResult.confirm;
      var reasonCodeId = dialogResult.reasonCodeId;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
  
      if (userConfirmed) {
          try {
  
              const result = await $flows.AsnOrders.unreceive_asn_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                  reasonCodeId: reasonCodeId
              });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully unreceived.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                  await $editor.refresh();
                  await $editor.tabs.licenseplates.activate();
                  return;
              }
  
              else {
  
                  const title = 'Unreceive Order';
                  const message = `Order ${$editor.entity.LookupCode} has been fully unreceived.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
                  await $editor.tabs.licenseplates.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Unreceive order error', 'An error occurred during unreceiving of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              await $editor.refresh();
              await $editor.tabs.licenseplates.activate();
          }
      }
  }
  }
  on_fda_hold_process(event = null) {
    return this.on_fda_hold_processInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fda_hold_processInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_fda_hold_process');
  if ($editor.vars.suppressFlows) { return; }
  $editor.vars.suppressFlows = true;
  
  let payload: any = {};
  
  $editor.fields.fda_hold.control.readOnly = true;
  
  try {
      if ($editor.fields.fda_hold.control.isChanged) {
  
          if ($editor.fields.fda_hold.control.value == true) {
  
              const formAwb = $editor.fields.awb_number.control.value;
              const formEntryNum = $editor.fields.entry_number.control.value;
  
  
              var pallets;
  
              const asnPalletCount = (await $datasources.AsnOrders.ds_get_asn_licenseplate_count_by_shipmentId.get({
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
              })).result;
  
              if ($utils.isDefined(asnPalletCount)) {
                  pallets = asnPalletCount.Total
              }
  
              const dialogResult = await $shell.opencustom_fda_hold_options_formDialog({
                  awb: formAwb,
                  entryNumber: formEntryNum,
                  palletCount: pallets
              });
  
              var userConfirmed = dialogResult.confirm;
              var awb = dialogResult.awb;
              var entryNumber = dialogResult.entryNumber;
              var palletCount = dialogResult.palletCount;
  
              if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
                  $editor.refresh();
                  return;
              }
  
              if (userConfirmed) {
                  try {
                      // Get order details (project and warehouse)
                      const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.entity.Id })).result;
                      if ($utils.isDefined(order)) {
                          const projectId = order.ProjectId;
                          var warehouseId = order.PreferredWarehouseId;
                          const lookupcode = order.LookupCode;
                          const ownerReference = order.OwnerReference;
                          const vendorReference = order.VendorReference;
                          const accountId = order.AccountId;
                          const billingAddressId = order.BillingAddresId;
  
                          // If source warehouse is MEMI then use MEM-M2, ticket 142215: Component Revision - Controlled import on FDA Hold
                          const sourceWarehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: warehouseId })).result;
                          if (sourceWarehouse.Name == 'MEMI') {
  
                              const warehouse = (await $datasources.Locations.ds_get_warehouse_by_name.get({ name: 'MEM-M2' })).result;
                              if ($utils.isDefined(warehouse)) {
                                  warehouseId = warehouse[0].Id;
                              }
                          }
  
                          const orderClass = (await $datasources.PurchaseOrders.ds_get_orderclasses_by_lookupcode.get({ lookupcode: 'FDA' })).result;
                          if ($utils.isDefined(orderClass)) {
  
                              const orderClassId = orderClass[0].Id;
                              // Create FDA Order and Order Lines
  
                              const materialGroup = (await $datasources.custom_ds_get_orderlines_by_orderId_top1.get({ orderId: $editor.entity.Id })).result;
                              if ($utils.isDefined(materialGroup)) {
                                  var materialGroupId = materialGroup.Material.MaterialGroupId;
                                  if ($utils.isDefined(materialGroupId)) {
  
                                      const orderRequest = (await $flows.custom_create_fda_purchase_order_flow({
                                          projectId: projectId,
                                          warehouseId: warehouseId,
                                          lookupcode: lookupcode,
                                          orderClassId: orderClassId,
                                          awb: awb,
                                          entryNumber: entryNumber,
                                          palletCount: palletCount,
                                          ownerReference: ownerReference,
                                          vendorReference: vendorReference,
                                          accountId: accountId,
                                          billingAddressId: billingAddressId,
                                          materialGroupId: materialGroupId,
                                          controllerTypeId: 2,
                                          isFixedWeight: true,
                                          isFixedLength: true,
                                          isFixedVolume: true,
                                          shelfLifeSpan: materialGroup.Material.ShelfLifeSpan,
                                          storageCategoryRuleId: materialGroup.Material.StorageCategoryRuleId,
                                          allocationStrategyId: materialGroup.Material.AllocationStrategyId,
                                          allocationStrategyWorkflowId: materialGroup.Material.AllocationStrategyWorkflowId
                                      }));
  
                                      if ($utils.isDefined(orderRequest)) {
  
                                          if ($utils.isDefined(orderRequest.reason)) {
                                              throw new Error(`Unable to create FDA Order ${orderRequest.reason}`);
                                          }
                                          else {
                                              // Update the current order
                                              payload.FDAHold = $editor.fields.fda_hold.control.value;
                                              payload.AWBNumber = awb;
                                              payload.EntryNumber = entryNumber;
                                              await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
                                              // Open the newly created order
                                              if ($utils.isDefined(orderRequest.orderId)) {
  
                                                  var orderId = orderRequest.orderId;
                                                  await $shell.FootPrintManager.openpurchase_order_editor({ orderId: orderId });
  
                                              }
                                          }
                                      } else {
                                          throw new Error('Unable to create FDA order.')
                                      }
                                  }
                              }
                          } else {
                              throw new Error('Order class FDA does not exist.')
                          }
                      }
                      else {
                          throw new Error('Unable to determine details for the current order.')
                      }
                  }
                  catch (error) {
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
                      await $shell.openErrorDialog('FDA Hold Order Creation Error', 'An error occurred during the creation of the FDA Hold order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
                  }
              }
          }
          else {
              const awb = $editor.fields.awb_number.control.value;
              const entryNumber = $editor.fields.entry_number.control.value;
              const lookupcode = $editor.entity.LookupCode;
              const projectId = $editor.entity.ProjectId;
  
              if ($utils.isAllDefined(awb, entryNumber, projectId)) {
                  const order = (await $datasources.custom_ds_get_fda_order_by_awb_entrynumber.get({
                      awb: awb,
                      entrynumber: entryNumber,
                      projectId: projectId,
                      lookupcode: lookupcode
                  })).result;
  
                  if ($utils.isDefined(order)) {
  
                      const orderId = order.Id;
                      const shipmentId = order.ShipmentOrderLookups[0].ShipmentId;
  
                      const orderStatusId = order.OrderStatusId;
  
                      // Check the status of the order, if in a created state then cancel it.
                      if (orderStatusId == 1) {
                          const cancelRequest = (await $flows.PurchaseOrders.cancel_purchase_order_flow({
                              orderId: orderId
                          }));
                          if ($utils.isDefined(cancelRequest?.reasons)) {
  
                              const reasons = cancelRequest.reasons;
                              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
  
                          }
                          else {
                              // Update the current order
                              payload.FDAHold = $editor.fields.fda_hold.control.value;
                              await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
                              await $shell.openInfoDialog('FDA Order Cancelled', 'FDA order was in a created status and as a result has now been cancelled.')
                          }
                      }
                      // Order is already cancelled
                      else if (orderStatusId == 8) {
  
                          payload.FDAHold = $editor.fields.fda_hold.control.value;
                          await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
                          $shell.openInfoDialog('FDA Hold Released', 'FDA order was already manually cancelled.')
  
                      }
                      else {
                          const receivingTasks = (await $datasources.PurchaseOrders.ds_get_completed_receiving_tasks_by_orderId_top1.get({
                              orderId: orderId
                          })).result;
                          //If the FDA Order is in Processing Status but nothing is received, then cancel the order
                          if (!$utils.isDefined(receivingTasks)) {
  
                              const cancelRequest = (await $flows.PurchaseOrders.cancel_purchase_order_flow({
                                  orderId: orderId
                              }));
                              if ($utils.isDefined(cancelRequest?.reasons)) {
  
                                  const reasons = cancelRequest.reasons;
                                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                              }
                              else {
                                  // Update the current order
                                  payload.FDAHold = $editor.fields.fda_hold.control.value;
                                  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
                                  $shell.openInfoDialog('FDA Order Cancelled', 'FDA order was in a processing status without received inventory and as a result has now been cancelled.')
                              }
                          }
                          else {
                              // If the FDA Order is partially received, then tell users that they need to finish receiving before this can be removed.
                              const releasedRecvTasks = (await $datasources.PurchaseOrders.ds_get_released_receiving_tasks_by_orderId_shipmentId_top1.get({
                                  orderId: orderId,
                                  shipmentId: shipmentId
                              })).result;
  
                              if ($utils.isDefined(releasedRecvTasks)) {
                                  throw new Error('FDA Order has been partially received, make sure the order is fully received before continuing with the release.')
                              }
                              else {
                                  const releasedPutaway = (await $datasources.PurchaseOrders.ds_get_released_putaway_tasks_by_shipmentId_top1.get({
                                      shipmentId: shipmentId
                                  })).result;
  
                                  // Cancel any released putaway task
                                  if ($utils.isDefined(releasedPutaway)) {
                                      const cancelRequest = (await $flows.PurchaseOrders.cancel_putaway_tasks_flow({
                                          shipmentId: shipmentId
                                      }));
  
                                      if ($utils.isDefined(cancelRequest?.reasons)) {
                                          const reasons = cancelRequest.reasons;
                                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                                      }
                                  }
                                  // Check if there are completed putaway tasks, if so continue, else throw a different message
                                  const putawayTasks = (await $datasources.PurchaseOrders.ds_get_completed_putaway_tasks_by_shipmentId.get({
                                      shipmentId: shipmentId
                                  })).result;
  
                                  if ($utils.isDefined(putawayTasks)) {
                                      // Create released move tasks for each completed putaway task
                                      const moveRequest = (await $flows.custom_create_fda_lp_moves_flow({
                                          orderId: orderId,
                                          shipmentId: shipmentId
                                      }));
  
                                      if ($utils.isDefined(moveRequest?.reasons)) {
                                          const reasons = moveRequest.reasons;
                                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                                      } else {
                                          // Update the current order
                                          payload.FDAHold = $editor.fields.fda_hold.control.value;
                                          await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
                                          $shell.openInfoDialog('FDA Hold Released', 'FDA hold has been successfully released and as a result move tasks have been created.')
                                      }
                                  }
                                  else {
                                      // Update the current order
                                      payload.FDAHold = $editor.fields.fda_hold.control.value;
                                      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
                                      $shell.openInfoDialog('FDA Hold Released', 'FDA hold has been successfully released, no move tasks created as all related putaway tasks were in a cancelled state.')
  
                                  }
                              }
                          }
                      }
                  }
                  else {
                      throw new Error('FDA Order could not be found, unable to create move tasks.')
                  }
              }
              else {
                  throw new Error('Unable to determine FDA Order, AWB and Entry Number fields are required.')
              }
          }
      }
  }
  
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.openErrorDialog('Error changing FDA hold', targetError.message);
  }
  
  $editor.refresh();
  }
  on_update_project_clicked(event = null) {
    return this.on_update_project_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_project_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_update_project_clicked');
  //O.Arias - 04/12/2023
  
  let orderId = $editor.entity.Id;
  let shipmentId = $editor.entity.ShipmentOrderLookups[0].ShipmentId;
  let projectId = $editor.entity.ProjectId;
  let ownerId = $editor.entity.Project.OwnerId;
  
  const result = await $flows.custom_is_asn_order_updatable_project_flow({
      orderId: orderId,
      shipmentId: shipmentId
  });
  
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.openErrorDialog('Order project cannot be updated.', reason);
      return;
  } else {
      const confirm = await $shell.openConfirmationDialog('Update order', `Are you sure you want to change the project on order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              //Show active project list
              const project_dialog = await $shell.opencustom_project_update_project_formDialog({ ownerId: ownerId, currentProjectId: projectId })
  
              //Check if the user selected a project
              if (project_dialog.confirm) {
  
                  let targetProjectId = project_dialog.projectId;
  
                  //const title = 'Update Project';
                  //const message = 'From Project Id: ' + projectId + ' to Project Id: ' + targetProjectId;
                  //await $shell.AsnOrders.openInfoDialog(title, message);
  
                  //Run the verification now with the target project id
                  const verification = await $flows.custom_is_asn_order_updatable_project_flow({
                      orderId: orderId,
                      shipmentId: shipmentId,
                      targetProjectId: targetProjectId
                  });
  
                  const verification_reason = verification.reason;
  
                  if ($utils.isDefined(verification_reason)) {
                      await $shell.openErrorDialog('Order project cannot be updated.', verification_reason);
                      return;
                  } else {
  
                      //Perform the project update on the order
                      const result = await $flows.custom_update_project_asn_order_flow({
                          orderId: orderId,
                          shipmentId: shipmentId,
                          targetProjectId: targetProjectId
                      });
  
                      const reason = result.reason;
  
                      if ($utils.isDefined(reason)) {
                          await $shell.openErrorDialog('Order cannot be updated.', reason);
                          return;
                      }
                      else {
  
                          let target_orderId = result.targetOrderId;
                          const target_order = (await $datasources.custom_ds_asn_order_editor.get({orderId: target_orderId})).result;
                          let target_order_lookupCode = target_order.LookupCode;
  
                          await $shell.FootPrintManager.openasn_order_editor({ order_id: target_orderId }, true);
  
                          const title = 'Update Order';
  
                          let message = '';
  
                          if ($editor.entity.LookupCode == target_order_lookupCode) {
                              message = `Order ${$editor.entity.LookupCode} was updated!`;
                          } else {
                              message = `Order ${$editor.entity.LookupCode} was replaced by order ${target_order_lookupCode}!`;
                          };
  
                          await $shell.AsnOrders.openInfoDialog(title, message);
  
                      }
                  }
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.openErrorDialog('Update order error', 'An error occurred during updating of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_fda_order_clicked(event = null) {
    return this.on_fda_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fda_order_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_fda_order_clicked');
  // Look for FDA order where the order class is of type FDA and the AWB and Entry Number UDFs match
  
  
  const awb = $editor.fields.awb_number.control.value;
  const entryNumber = $editor.fields.entry_number.control.value;
  const projectId = $editor.entity.ProjectId;
  const lookupcode = $editor.entity.LookupCode;
  
  if ($utils.isAllDefined(awb, entryNumber, projectId)) {
      const order = (await $datasources.custom_ds_get_fda_order_by_awb_entrynumber.get({
          awb: awb,
          entrynumber: entryNumber,
          projectId: projectId,
          lookupcode: lookupcode
      })).result;
  
      if ($utils.isDefined(order)) {
  
          const orderId = order.Id;
  
          await $shell.opencustom_purchase_order_editor({ orderId: orderId });
  
      } else {
  
          $shell.openInfoDialog('FDA Order','FDA Order not available.')
      }
  
  }
  else {
  
      $shell.openInfoDialog('FDA Order','FDA Order not available.')
  }
  }
  on_ship_from_cleared(event = null) {
    return this.on_ship_from_clearedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clearedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_ship_from_cleared');
  let payload: any = {};
  
  payload.AccountId = null;
  payload.BillingAddresId = null;
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
  await $editor.refresh();
  
  }
  on_auto_transfer_clicked(event = null) {
    return this.on_auto_transfer_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_transfer_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_auto_transfer_clicked');
  let targetWarehouseName = 'MEM-M2';
  
  try {
      let targetWarehouse = (await $datasources.Locations.ds_get_warehouse_by_name.get({ name: targetWarehouseName })).result[0];
  
      if (!$utils.isDefined(targetWarehouse)) {
          throw new Error(`Target warehouse '${targetWarehouseName}' does not exist.`);
      }
  
      if (!$editor.vars.suppressPrompts) {
          var isConfirm = (await $shell.openConfirmationDialog(`Automatically transfer order`, `Are you sure you want to automatically transfer the contents of this order to warehouse ${targetWarehouse.Name}?`, 'Confirm', 'Cancel'));
      }
      else { isConfirm = true; }
  
      if (isConfirm) {
          $editor.toolbar.auto_transfer.control.readOnly = true;
          $editor.toolbar.auto_transfer.control.icon = 'ms-Icon ms-Icon--ProgressRingDots';
          $editor.toolbar.auto_transfer.control.label = 'Transferring order...';
  
          if (!$utils.isDefined($editor.vars.warehouseTransferId)) {
              let creationResult = (await $flows.WarehouseTransfers.create_warehouse_transfer_from_completed_inbound_order_flow({
                  order_id: $editor.entity.Id,
                  target_warehouse_id: targetWarehouse.Id
              }));
  
              if ($utils.isDefined(creationResult.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: creationResult.reasons })).formattedValue);
              }
  
              $editor.vars.warehouseTransferId = creationResult.warehouse_transfer_id;
          }
  
          let transferResult = (await $flows.WarehouseTransfers.auto_fulfill_warehouse_transfer_flow({
              warehouse_transfer_id: $editor.vars.warehouseTransferId
          }));
  
          if ($utils.isDefined(transferResult.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: transferResult.reasons })).formattedValue);
          }
  
          $shell.openInfoDialog('Successfully transferred order', `Order ${$editor.entity.LookupCode} has been successfully transferred to warehouse ${targetWarehouse.Name}`);
  
          await $editor.refresh();
      }
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      $shell.openErrorDialog('Error automatically transferring order', targetError.message);
  }
  
  $editor.toolbar.auto_transfer.control.readOnly = false;
  $editor.toolbar.auto_transfer.control.icon = 'ms-Icon ms-Icon--DependencyAdd';
  $editor.toolbar.auto_transfer.control.label = 'Auto transfer';
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_process_clicked');
  const result = await $flows.AsnOrders.is_asn_order_processable_flow({
      orderId: $editor.entity.Id
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Process order', `Are you sure you want to process order ${$editor.entity.LookupCode}?`, 'Confirm', 'Cancel');
      if (confirm) {
          try {
  
              const result = await $flows.AsnOrders.process_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                  return;
              }
              else {
                  const title = 'Process order';
                  const message = `Order ${$editor.entity.LookupCode} has been processed.`;
                  await $shell.AsnOrders.openInfoDialog(title, message);
                  await $editor.refresh();
              }
          } catch (error) {
  
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Error processing order', 'An error occurred while processing the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_attachments_clicked');
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_surveys_clicked');
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $editor: app_custom_asn_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_asn_order_editor.on_custom_fields_clicked');
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'Order', entityKeys: [{name: 'Id', value: $editor.entity.Id}]}, 'modal');
  }
  //#endregion private flows
}
